
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import axios from 'axios';
import auth from './helpers/auth'
import { faEdit, faSprayCan, faSave, faTimesCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import LittleSpinner from './LittleSpinner';
import ProgressBar from './ProgressBar';
import { FormControlLabel, Checkbox } from '@material-ui/core';

const SPRAY_AUTOMATION_SECONDS = 5;
class WaterSpraySystemV1 extends Component {
  state = {
    enableSpray: this.props.generator.spray_enable ?? 0,
    editingSprayAutomationSettings: false,
    savingSprayAutomationSettings: false,
    spraying: false,
    refilling:false,
    syncing: false,
    success: false,
    selectedSprayAutomationSeconds: SPRAY_AUTOMATION_SECONDS,
    selectedSprayAutomationDays: this.props.generator.spray_automation_days ?? 0,
    alert:false
  }
  constructor(props) {
    super(props);
    // this.handleChangeSprayAutomationSeconds = this.handleChangeSprayAutomationSeconds.bind(this);
    this.handleChangeSprayAutomationDays = this.handleChangeSprayAutomationDays.bind(this);
    this.handleChangeSprayEnable = this.handleChangeSprayEnable.bind(this);
  }
  /**
   * sync the water spray system
   */
  componentDidMount() { 
    if (this.props.generator.spray_enable) {
      this.syncWaterSpraySystem();
    }

  }
  /**
   * Displayes an alert on the top of the view
   * @param {string} type the type of the alert
   * @param {string} msg The message of the alert
   */
  setAlert = (type, msg, fetch = false) => {
    this.setState({
        alert: true,
        alertClass: "alert alert-" + type + " text-center",
        alertMsg: msg,
    })
    // smoothToTop();
    setTimeout(() => {
        this.setState({
            alert: false,
            alertClass: '',
            alertMsg: '',
        });
        if (fetch) {
            this.search()
        }

    }, 5000)
}
  editSprayAutomationSettings() {
    this.setState({editingSprayAutomationSettings:true})
    
  }
  syncWaterSpraySystem() {
    this.setState({
      syncing: true
    });
    const config = {
      headers: { Authorization: `Bearer ${auth.token}` }
    };
    const data = {
      generator_id: this.props.generator.id
    }
    axios.post(process.env.REACT_APP_API_URL + `generators/sync-spray-system`, data, config)
    .then(response => {
        setTimeout(() => this.setState({
          success: true,
          syncing: false
        }), 1000)
      console.log(response);
        this.props.generator.water_capacity = response.data.generator_spray_data.water_capacity;
        this.props.generator.water_available = response.data.generator_spray_data.water_available;
        this.props.generator.spray_synced = response.data.generator_spray_data.spray_synced;
        // this.props.generator.water_capacity = 100;
        // this.props.generator.water_available = 100;
      this.setAlert('success', 'The sprayer system data synced successfully');
    })
    .catch(error => {
        console.log("ERROR: got error")
        if( error.response === undefined )
            return
      this.handleErrorResponse(error.response.data.error)
      this.setState({syncing: false});
    });
  }
  refillWater() {
    this.setState({refilling: true});
    const config = {
      headers: { Authorization: `Bearer ${auth.token}` }
    };
    const data = {
      generator_id: this.props.generator.id
    }
    axios.post(process.env.REACT_APP_API_URL + `generators/refill-water`, data, config)
    .then(response => {
        setTimeout(() => this.setState({
          success: true,
          refilling:false
        }), 200)
        this.props.generator.water_capacity = response.data.generator_spray_data.water_capacity;
        this.props.generator.water_available = response.data.generator_spray_data.water_available;
        this.props.generator.spray_synced = response.data.generator_spray_data.spray_synced;
        this.setAlert('success', 'The generator sprayer refilled successfully')
    })
    .catch(error => {
        console.log("ERROR: got error")
        if( error.response === undefined )
            return
        this.handleErrorResponse(error.response.data.error)
    });
  }
  sprayWater() {
    console.log('spraying water');
    this.setState({ spraying: true});
    const config = {
      headers: { Authorization: `Bearer ${auth.token}` }
    };
    const data = {
      generator_id: this.props.generator.id,
      duration: 5
    }
    axios.post(process.env.REACT_APP_API_URL + 'generators/spray-water', data, config)
    .then(response => {
        setTimeout(() => this.setState({
          success: true,
          spraying: false
        }), 200)
        console.log(response);
        this.props.generator.water_capacity = response.data.generator_spray_data.water_capacity;
        this.props.generator.water_available = response.data.generator_spray_data.water_available;
        this.props.generator.spray_synced = response.data.generator_spray_data.spray_synced;
        this.setAlert('success', 'The generator sprayer manual spray initiated successfully')
    })
    .catch(error => {
        console.log("ERROR: got error")
        if( error.response === undefined )
          return
        this.setState({ spraying: false});
        this.handleErrorResponse(error.response.data.error)
    });
  }

  
  // handleChangeSprayAutomationSeconds(event) { 
  //   console.log('changing value');
  //   const selectedValue = event.target.value;
  //   console.log(selectedValue);
  //   this.setState({selectedSprayAutomationSeconds: selectedValue});
  // }

  handleChangeSprayAutomationDays(event) { 
    console.log('changing value');
    const selectedValue = event.target.value;
    console.log(selectedValue);
    this.setState({selectedSprayAutomationDays: selectedValue});
  }

  // enable / disable
  handleChangeSprayEnable(event) {
    let enableSpray = 0;
    if (event.target.checked) {
      enableSpray = 1;
      this.syncWaterSpraySystem();
    } else {
      enableSpray = 0;
      this.setState({
        selectedSprayAutomationSeconds: 0,
        selectedSprayAutomationDays: 0
      });
    }

    this.setState({ enableSpray });
    this.saveEnableSpray( enableSpray );
    
  }
  // save and cancel
  saveSprayAutomationSettings() {
    console.log('saving spray automation settinds');
    this.setState({savingSprayAutomationSettings: true});
    const { selectedSprayAutomationSeconds, selectedSprayAutomationDays } = this.state;
    const config = {
      headers: { Authorization: `Bearer ${auth.token}` }
    };
    const data = {
      generator_id: this.props.generator.id,
      automation_seconds: selectedSprayAutomationSeconds,
      automation_days: selectedSprayAutomationDays
    }
    axios.post(process.env.REACT_APP_API_URL + 'generators/save-spray-automation-settings', data, config)
    .then(response => {
        setTimeout(() => this.setState({
            success: true
        }), 1000)
      console.log(response);
      this.setState({savingSprayAutomationSettings: false, editingSprayAutomationSettings:false});
      this.props.generator.spray_automation_seconds = selectedSprayAutomationSeconds;
      this.props.generator.spray_automation_days = selectedSprayAutomationDays;
      if (response.data.error) {
        this.setAlert('warning', response.data.message);
      } else {
        this.setAlert('success', 'The generator sprayer automation settings has been updated successfully')
      }
    })
    .catch(error => {
        console.log("ERROR: got error")
        if( error.response === undefined )
            return
      this.handleErrorResponse(error.response.data.error)
      this.setState({savingSprayAutomationSettings: false, editingSprayAutomationSettings:false});
      
    });
  }

  cancelSprayAutomationSettings() {
    this.setState({
      selectedSprayAutomationSeconds: this.props.generator.spray_automation_seconds ?? SPRAY_AUTOMATION_SECONDS,
      selectedSprayAutomationDays: this.props.generator.spray_automation_days ?? 0,
      editingSprayAutomationSettings:false
    });
  }
  // save and cancel
  saveEnableSpray(enableSpray) {
    console.log("===>>>>" + enableSpray);
    const config = {
      headers: { Authorization: `Bearer ${auth.token}` }
    };
    const data = {
      generator_id: this.props.generator.id,
      enable_spray: enableSpray
    }
    axios.post(process.env.REACT_APP_API_URL + 'generators/save-spray-enable', data, config)
    .then(response => {
        setTimeout(() => this.setState({
            success: true
        }), 1000)

      this.props.generator.spray_enable = enableSpray;
        this.setAlert('success', 'The generator sprayer is enabled / disabled successfully')
    })
    .catch(error => {
        console.log("ERROR: got error")
        if( error.response === undefined )
            return
        this.handleErrorResponse(error.response.data.error)
    });
  }
  handleErrorResponse(error) { 
    this.setAlert('danger', error);
  }
  render() {
    const generator = this.props.generator
    const { editingSprayAutomationSettings, savingSprayAutomationSettings,selectedSprayAutomationSeconds, selectedSprayAutomationDays, enableSpray, spraying, refilling} = this.state;
    let waterStatusIcon, availableWaterPercentage, refillWaterIcon, waterCapaticy, availableWater,waterSprayIcon;
    availableWater = generator.water_available ?? 0;
    waterCapaticy = generator.water_capacity ?? 0;
    availableWater = (availableWater > 0) ? availableWater : 0;
    if ( waterCapaticy == 0) {
      availableWaterPercentage = 0;
    } else {
      availableWaterPercentage = 100 * availableWater / waterCapaticy;
      availableWaterPercentage = availableWaterPercentage.toFixed(2);
    }
    let availableWaterPercentageBgColor = "#49E20E";
    // get progressbar color based on percentage
    if (availableWaterPercentage > 50 && availableWaterPercentage <= 75) {
      availableWaterPercentageBgColor = "#99ccff";
    } else if (availableWaterPercentage > 25 && availableWaterPercentage <= 50) {
      availableWaterPercentageBgColor = "#FFA500";
    } else if (availableWaterPercentage >= 0 && availableWaterPercentage <= 25) {
      availableWaterPercentageBgColor = "#ff0000";
    } 
    
    // near out of water
    // show red icon when the water is below 10%
    if (availableWaterPercentage <= 10) {
      // waterStatus  = <ColoredCircle color="#BF0000" />
      waterStatusIcon  = <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#BF0000"><rect fill="none" height="24" width="24"/><path d="M12,2c-5.33,4.55-8,8.48-8,11.8c0,4.98,3.8,8.2,8,8.2s8-3.22,8-8.2C20,10.48,17.33,6.55,12,2z M7.83,14 c0.37,0,0.67,0.26,0.74,0.62c0.41,2.22,2.28,2.98,3.64,2.87c0.43-0.02,0.79,0.32,0.79,0.75c0,0.4-0.32,0.73-0.72,0.75 c-2.13,0.13-4.62-1.09-5.19-4.12C7.01,14.42,7.37,14,7.83,14z"/></svg>
    } else {
      // waterStatus  = <ColoredCircle color="#49E20E" />
      waterStatusIcon  = <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#49E20E"><rect fill="none" height="24" width="24"/><path d="M12,2c-5.33,4.55-8,8.48-8,11.8c0,4.98,3.8,8.2,8,8.2s8-3.22,8-8.2C20,10.48,17.33,6.55,12,2z M7.83,14 c0.37,0,0.67,0.26,0.74,0.62c0.41,2.22,2.28,2.98,3.64,2.87c0.43-0.02,0.79,0.32,0.79,0.75c0,0.4-0.32,0.73-0.72,0.75 c-2.13,0.13-4.62-1.09-5.19-4.12C7.01,14.42,7.37,14,7.83,14z"/></svg>
      
    }
    // for refill water 
    // only refill available for admin 
    if (auth.role_id <= 1) {
      // enable refill water when water level is below than 100%
      if (availableWaterPercentage < 100) {
        if (refilling) {// check the refill is in progress
          refillWaterIcon = <LittleSpinner style={{ marginLeft: "10px" }} />
        } else {
          refillWaterIcon = <a onClick={() => { this.refillWater() }} style={watersprayIcons}><img src="/generator-actions/refill_button.png" width="111" height="66" /></a>
        }
      
      } else {
        refillWaterIcon = <img className="disabled" src="/generator-actions/refill_button.png" width="111" height="66" />
      }
    }
    const checkWaterSpray = enableSpray ? true : false;
    // for spray water icon
    //disable spray icon when the water level is before 5% 
    if (availableWaterPercentage <= 5) {
      waterSprayIcon = <FontAwesomeIcon icon={faSprayCan} className="waterspray-icons" style={{marginLeft:"10px"}} color='#cacaca'  size="lg" />
    } else {
      const sprayItems = [];
      for(let i=0;i<=availableWater;i++){
        sprayItems.push(<MenuItem color="#ffffff" value={i}>{i}</MenuItem>)
      }
      // check if the spraying is in progress
      if (spraying) { 
        waterSprayIcon =  <LittleSpinner style={{ marginLeft: "10px" }} />
      } else {
        waterSprayIcon = <div>
        <FontAwesomeIcon onClick={ () => this.sprayWater()} icon={faSprayCan} className="waterspray-icons" style={watersprayIcons} size="lg" />
        </div>
      }
     
    }
    let alert = '';
    let showSyncWarningItems = '';
    if (this.props.generator.spray_synced == false & this.props.generator.spray_enable) { 
      showSyncWarningItems = <span style={{ fontSize: '1rem' }}>
                  <FontAwesomeIcon icon={faExclamationTriangle} size="lg" color="#FFFF00" title='The water spraying system doesnot synced' />
                </span>
    }
    if (this.state.alert)
        alert = <div className={this.state.alertClass} style={{ margin: "0 20% 15px" }} role="alert">{this.state.alertMsg}</div>
        return (
          <>
            <tr>
              <td >Water Spraying</td>
              <td ><img src={`/generator-actions/${generator.spray_enable?"on":"off"}_button.png`} width="111" height="66" onChange={this.handleChangeSprayEnable}/>
              {this.state.syncing && <span className="water-spray-sync" style={{fontSize:'1rem'}}><LittleSpinner style={{ marginLeft: "10px" }} /></span>}
              </td>
              <td >{waterStatusIcon}</td>
              <td > {showSyncWarningItems}</td>
          </tr>
          {checkWaterSpray && <><tr>
              <td>Manual Spraying</td>
              <td ><img src="/generator-actions/power_button.png" width="74" height="74" />
              {waterSprayIcon}</td>
              <td >&nbsp;</td>
              <td >&nbsp;</td>
          </tr>
              <tr>
              <td >Spraying Automation</td>
              <td >
              {editingSprayAutomationSettings ?
                      <div className="sprayAutomationEditContainer d-inline-block">
                        {selectedSprayAutomationSeconds}
                        &nbsp;seconds&nbsp;every&nbsp;
                        <Select className='form-control' key="automation-days" style={{ width: 'auto' }} value={selectedSprayAutomationDays} onChange={this.handleChangeSprayAutomationDays}>
                          {[...Array(32)].map((x, i) => <MenuItem key={i}  value={i}>{i}</MenuItem>)}
                        </Select>
                        &nbsp;day(s)
                        {savingSprayAutomationSettings ?
                          <LittleSpinner style={{ marginLeft: "10px" }} />
                          :
                          <div className='d-inline-block'>
                            <FontAwesomeIcon icon={faSave} style={watersprayIcons} size="lg" onClick={() => this.saveSprayAutomationSettings()} />
                            <FontAwesomeIcon icon={faTimesCircle} style={watersprayIcons} size="lg" onClick={() => { this.cancelSprayAutomationSettings() }} />
                          </div>
                        }
                        
                      </div>
                      :
                      // <FontAwesomeIcon icon={faEdit} className="waterspray-icons" style={watersprayIcons} size="lg" onClick={() => this.editSprayAutomationSettings()} />
                      <a style={watersprayIcons} onClick={() => this.editSprayAutomationSettings()} ><img src="/generator-actions/set_button.png" width="111" height="66" /></a>
                    }

                </td>
              <td >
              {editingSprayAutomationSettings
                      ? "" :
                      <div className='d-inline-block'>{selectedSprayAutomationSeconds} seconds every {selectedSprayAutomationDays} days</div>
                    }
                    
              </td>
              <td >{this.state.alert && <div className={this.state.alertClass} style={{ margin: "0 20% 15px" }} role="alert">{this.state.alertMsg}</div>}</td>
          </tr>
          <tr>
              <td>Tank Level</td>
              <td width={"30%"}><img src="/generator-actions/water-tank.png" width="375" height="51" />
              <ProgressBar progress={availableWaterPercentage} enableBackground={true} bgImage={'linear-gradient(to right, #BD3A87, #476FB2, #94C05B)'} bgcolor={availableWaterPercentageBgColor} height="30" /></td>
              <td >{availableWaterPercentage}</td>
              <td >{this.state.alert && <div className={this.state.alertClass} style={{ margin: "0 20% 15px" }} role="alert">{this.state.alertMsg}</div>}</td>
          </tr>
          {auth.role_id <= 1 && <tr>
              <td >Refill Tank</td>
              <td >{refillWaterIcon}</td>
              <td >{this.state.alert && <div className={this.state.alertClass} style={{ margin: "0 20% 15px" }} role="alert">{this.state.alertMsg}</div>}</td>
              <td ></td>
          </tr>
          }
      </>
      }
          </>
          // <div style={{ "marginTop": "10px", "width": "100%" }}>
          //   <h2 className="my-title2">
             
          //     Water Spraying System
          //     <span className="water-spray-enable mx-1">
          //     <FormControlLabel
          //         control={<Checkbox style={{ color: "#ffffff" }} />}
                  
          //         checked={checkWaterSpray}
          //         style={{ color: "#ffffff" }}
          //       />
          //     </span>
              
          //   </h2>
          //   <div>{alert}</div>
          //   {checkWaterSpray && <>
          //       <tr style={normalBreak}>
          //         <th className="d-none d-md-table-cell" style={{ textAlign: "center", color: "#E4BA06" }}>Water Percentage</th>
          //         <th className="d-none d-md-table-cell" style={{ textAlign: "center", color: "#E4BA06" }}>Water Status</th>
          //         {auth.role_id <= 1 &&
          //           <th className="d-none d-md-table-cell" style={{ textAlign: "center", color: "#E4BA06" }}>Refill Water</th>
          //         }
          //         <th className="d-none d-md-table-cell" style={{ textAlign: "center", color: "#E4BA06" }}>Spray</th>
          //         <th className="d-none d-md-table-cell" style={{ textAlign: "center", color: "#E4BA06" }}>Spray Automation Settings</th>
          //       </tr>
          //       <tr>
          //         <td className="text-lg-center text-left">
          //           <span className="d-lg-none font-weight-bold">
          //             Water Percentage:&nbsp;
          //           </span>
    
          //           <ProgressBar progress={availableWaterPercentage} bgcolor={availableWaterPercentageBgColor} height="30" />
          //         </td>
          //         <td className="text-lg-center text-left">
          //           <span className="d-lg-none font-weight-bold">
          //             Water Status:&nbsp;
          //           </span>
          //           {waterStatusIcon}</td>
          //         {auth.role_id <= 1 &&
          //           <td className="text-lg-center text-left">
          //             <span className="d-lg-none font-weight-bold">
          //               Refill Water:&nbsp;
          //             </span>
          //             </td>
          //         }
          //         <td className="text-lg-center text-left">
          //           <span className="d-lg-none font-weight-bold">
          //             Spray:&nbsp;
          //           </span>
                    
          //         </td>
          //         <td className="text-lg-center text-left">
          //           <span className="d-lg-none font-weight-bold">
          //             Spray Automation Settings:&nbsp;
          //           </span>
                           
                    
          //         </td>
          //       </tr>
          //       </>
          //   }
          //   </div>
        )
  }
}

const normalBreak = {
  wordBreak: "normal",
  backgroundColor: "#2A4B6E",
  color: "#E4BA06",
  borderColor: "#2A4B6E",
}

const wordBreak = {
  wordBreak: "break-word"
}
const watersprayIcons = {
  cursor: "pointer",
  marginLeft: "10px"
}


export default WaterSpraySystemV1
