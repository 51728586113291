import React, { Component } from "react";
import FarmsPerSite from "../FarmsPerSite";
import { DateRangePicker } from '../DateRangePicker'
import axios from 'axios'
import qs from 'qs';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPlay } from "@fortawesome/free-solid-svg-icons";
import auth from '../helpers/auth'
import { smoothToTop } from '../helpers/misc'
import { Link } from 'react-router-dom'
import Spinner from '../Spinner'

import Filters from '../Filters';

import { NavLink } from "react-router-dom";

class Overview extends Component {
    /**
     * The overview page. Contains information about each site or each
     * company according to the user access. It contains a generator summary
     * (active or inactive) and an event summary with a date range picker.
     */

    state = {
        showCalendar: true,
        dateRange: [],
        data: [],
        spinner: true,
        companyData: {},
        dataLoaded: false,

    }

    showCalendar = () => {
        this.setState({
            showCalendar: !this.state.showCalendar
        })
    }
    onChangeDate = (date) => {
        if (date) {
            this.setState({
                dateRange: date
            }, () => this.fetchOverAllData())
        } else {
            this.showCalendar();
        }
    }

    componentDidMount() {
        smoothToTop();
        document.title = "BMS App - Overview"
        this.setState({spinner: false});
        let yesterday = new Date()
        yesterday.setDate(new Date().getDate() - 1)
        let today = new Date()
        this.setState({
            dateRange: [yesterday, today]
        }, 
        // () => this.fetchOverAllData()
        )
    }

    fetchOverAllData(onPageLoad = false, selectedCompanies = [], selectedSites = [], selectedFarms = []) {
        //set the correct day
        const { dateRange} = this.state
        let params = {}
        params["from"] = dateRange[0].getFullYear() + "-" + (dateRange[0].getMonth() + 1) + "-" + dateRange[0].getDate()
        params["to"] = dateRange[1].getFullYear() + "-" + (dateRange[1].getMonth() + 1) + "-" + dateRange[1].getDate()
        console.log("companies: ", selectedCompanies);
       
        params["companies"] = selectedCompanies.map(c => c.id);
        params["sites"] = selectedSites.map(s => s.id);
        params["farms"] = selectedFarms.map(f => f.id);
        console.log("overall data for date range: ", params['from'], "--", params['to'])
        if( onPageLoad ===  false){
            this.setState({
                spinner: true
            })
        }
        //set the header
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` },
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        };

        //make the request for the data
        axios.get(process.env.REACT_APP_API_URL + 'fetch_overview_data', config)
            .then(response => {
                console.log("|||===>", response.data)
                var company = [];

                // if (response.data.length > 0) {
                //     var tmpCompany = response.data[0];
                //     company.push(tmpCompany);
                //     console.log(tmpCompany);
                //     if (tmpCompany.sites.length > 0) {
                //         company[0].sites = [];
                //         company[0].sites.push(tmpCompany.sites[0]);
                //     }
                // }
                // console.log('company data');
                // console.log(company);

                this.setState({
                    data: response.data,
                    spinner: false,
                    // showCalendar: false,
                    companyData: company
                }, () => { if(onPageLoad === false ) {
                    this.initCompanyData();
                } });
                if(onPageLoad === true ) {
                    this.setState({dataLoaded: true});
                    this.renderOverview();
                }
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    spinner: false
                })
            });
    }
    initFirstCompany() {
        let stateData = this.state.data;
        let companyData = []
        if (stateData.length > 0) {
            companyData.push({ ...stateData[0] });
            if (companyData[0].sites.length > 0) {
                companyData[0].sites = [];
                companyData[0].sites.push({ ...stateData[0].sites[0] });
                if (companyData[0].sites[0].farms.length > 0) {
                    companyData[0].sites[0].farms = [];
                    companyData[0].sites[0].farms.push({ ...stateData[0].sites[0].farms[0] });
                }
            }
        }
        console.log('company initial data');
        console.log(companyData);
        this.setState({
            companyData: companyData
        })
    }
    initCompanyData() {
        let stateData = this.state.data;
        let companyData = [];
        if (stateData.length > 0) {
            stateData.map( (d, idx) => {
                companyData.push({...d});
                // d.sites.map((site, siteIdx) => {
                //     companyData[idx].sites.push({...site}); 
                //     site.map( (farm, farmIdx) => {
                //         companyData[idx].sites[siteIdx].push({...farm}); 
                //     })
                // });
            });
        }
        console.log('company all data');
        console.log(companyData);
        this.setState({
            companyData: companyData
        })   
    }
    renderOverview = (selectedCompanies, selectedSites, selectedFarms) => {
        this.fetchOverAllData(false, selectedCompanies, selectedSites, selectedFarms);
        let dataFromFilters = { ...this.state.data }
        console.log(dataFromFilters);
        let companyData = [];
        selectedCompanies.map((selectedCompany, selectedCompanyIndex) => {
            this.state.data && this.state.data.map((company, companyIndex) => {
                if (selectedCompany.id == company.company_id) {
                    // companyData[selectedCompanyIndex] = { ...company };
                    companyData.push({ ...company })
                    companyData[selectedCompanyIndex].sites = [];
                    selectedSites.map((selectedSite, selectedSiteIndex) => {
                        //companyData[selectedCompanyIndex].sites[selectedSiteIndex].farms = [];
                        if (company.sites.length > 0) {
                            company.sites.map((site, siteIndex) => {
                                if (selectedSite.id == site.id && selectedSite.company_id == company.company_id) {
                                    //companyData[selectedCompanyIndex].sites[selectedSiteIndex] = { ...site };
                                    companyData[selectedCompanyIndex].sites.push({ ...site });
                                    companyData[selectedCompanyIndex].sites[selectedSiteIndex].farms = [];
                                    selectedFarms.map((selectedFarm, selectedFarmIndex) => {
                                        if (site.farms.length > 0) {
                                            site.farms.map((farm, farmIndex) => {
                                                if (selectedFarm.id == farm.id && selectedSite.company_id == company.company_id && selectedFarm.site_id == site.id) {
                                                    // companyData[selectedCompanyIndex].sites[selectedSiteIndex].farms[selectedFarmIndex] = { ...farm };
                                                    companyData[selectedCompanyIndex].sites[selectedSiteIndex].farms.push({ ...farm });
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });
        });
        console.log(companyData)
        this.setState({
            companyData: companyData
        });
    }

    render() {
        const { companyData, spinner, showCalendar, dateRange } = this.state
        console.log(companyData.length);
        let results = <h2 className="no-data">No companies found</h2>
        let spinnerTag = '';
        if (spinner)
            spinnerTag = <Spinner />

        if (companyData.length > 0) {
            results =
                <div>
                    {companyData.map((data, index) =>
                        <div key={index}>
                            <h2 style={titleStyle}> <span style={spanStyle}>Company: </span> {data.company_name} </h2>
                            {data.sites.length > 0 ?
                                data.sites.map(site =>
                                    <FarmsPerSite site={site} item={"summaries"} id={site.id} key={site.id} company_name={data.company_name} changeCurrentPlaceholder={this.props.changeCurrentPlaceholder} />
                                )
                                : <h2 className="no-data">No sites found</h2>

                            }

                        </div>
                    )}
                </div >
        }
        let canAdd = '';
        if (auth.role_id <= 1)
            canAdd =
                <div className="col text-right" style={addStyle}>
                    <div className="container">
                        <Link to="/registercompany" className="btn btn-primary ">
                            <FontAwesomeIcon icon={faPlus} />
                            <span className="d-none d-lg-inline">Add Company</span>
                        </Link>
                    </div>
                </div>
        return (
            <div className="container-inner-wrapper">
                <div className="row">
                    <div>
                        <h1 className="col my-title"> Wind Turbine Generator Overview - 
                        
                        <span className="date-picker-opener-with-title" >
                                {!showCalendar &&
                                    <button onClick={this.showCalendar} style={{ paddingLeft: "0px" }}>
                                        <div className="date-picker-opener">
                                            <span className="choose-date pull-left">Choose Date</span>
                                            <span className="pull-right"><FontAwesomeIcon icon={faPlay} size={"sm"} /></span>

                                        </div>
                                    </button>
                                }
                                {showCalendar &&
                                    <div>
                                        <DateRangePicker onChangeDate={this.onChangeDate} date={dateRange} />
                                    </div>
                                }
                            </span>

                        </h1>


                    </div>
                    {canAdd}


                </div>
                <hr />
                <div>
                    <Filters onFarmSelect={this.renderOverview} />
                    {spinner
                    ?   <Spinner /> 
                    :   <>
                        
                        {results}
                        </>
                    }
                </div>

            </div>
        );
    }
}

const titleStyle = {
    fontWeight: "200",
    fontSize: "2rem",
    color: "#000",
    padding: "20px",
    backgroundColor: "#31628A",
    width: "auto",
    maxWidth: "400px",
    height: "auto",
    color: "#ffffff",
    marginBottom: "30px",
    marginTop: "20px"
}
const spanStyle = { "color": "#fff" }


const addStyle = {
    paddingTop: "1.6%",
}

const dropdownListStyle = {
    padding: "0",
    backgroundColor: " #f8f9fa",
    color: "#212529",
    width: "70%",
    overflow: "auto",
}

const selectStyle = {
    padding: "5px",
}



export default Overview;
