import React, { Component } from 'react'
import Events from '../Events'
import { Button } from 'bootstrap'
import ImagePreviewV1 from './ImagePreviewV1'
import ClipPreviewV1 from './ClipPreviewV1'
import Modal from 'react-modal'
import axios from 'axios'
import auth from '../../helpers/auth'
import qs from 'qs';
import EventAnnotationV1 from './EventAnnotationV1'

export class DetailsTable extends Component {
    constructor() {
        super()
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.state = {
            showEventModal: false,
            isConfirmDelete: false,
            prevEventId: 0,
            nextEventId: 0,
            eventMedia: 'image'
        }
    }
    // get next and previous events based on search when page loads
    componentDidMount() {
        this.getNextPreviousEvents();
    }
    handleEventMediaBtn(media) {
        this.setState({ eventMedia: media });
    }
    getNextPreviousEvents() {
        const searched = localStorage.getItem('searched');
        let params = {
            'event_id': this.props.event.id
        }
        if (searched) {
            const eventTypes = JSON.parse(localStorage.getItem('event_types'));
            const extraEventTypes = JSON.parse(localStorage.getItem('extra_event_types'));
            const savedDate = JSON.parse(localStorage.getItem('search_date'));
            let date = [];
            savedDate.map(date_tmp => {
                date.push(new Date(date_tmp));
            })
            const selectedGenerators = JSON.parse(localStorage.getItem("selected_generators")) || [];
            const selectedCameras = JSON.parse(localStorage.getItem("selected_cameras")) || [];
            const selectedFarms = JSON.parse(localStorage.getItem("selected_farms")) || [];
            const selectedSites = JSON.parse(localStorage.getItem("selected_sites")) || [];
            const selectedCompanies = JSON.parse(localStorage.getItem("selected_companies")) || [];
            const page = localStorage.getItem("search_event_page") || '';

            // more filters
            const selectedBirds = JSON.parse(localStorage.getItem("selected_birds")) || [];
            const hasVideo = localStorage.getItem("has_video") ? (localStorage.getItem("has_video") === 'true') : true;
            const sortDirection = localStorage.getItem("sort_direction") || this.defaultSortDirection;
            const sortColumn = localStorage.getItem("sort_column") || this.defaultSortColumn;
            const cameraMode = localStorage.getItem('camera_mode');

            let selectedTypes = []
            if (eventTypes['detection'])
                selectedTypes.push('detection')
            if (eventTypes['deterrence'])
                selectedTypes.push('deterrence')
            if (eventTypes['shutdown'])
                selectedTypes.push('shutdown')

            // extra event types 
            Object.keys(extraEventTypes).map((extraEventType, extraEventTypeIndex) => {
                if (extraEventTypes[extraEventType]['checked']) {
                    selectedTypes.push(extraEventType);
                }
            })
            if (selectedCameras.length > 0) {
                const camIds = selectedCameras.map(cam => cam.id)
                params["cam"] = camIds
            }
            else if (selectedGenerators.length > 0) {
                const genIds = selectedGenerators.map(gen => gen.id)
                params["gen"] = genIds
            }
            else if (selectedFarms.length > 0) {
                const farmsIds = selectedFarms.map(farm => farm.id)
                params["farm"] = farmsIds
            }
            else if (selectedSites.length > 0) {
                const siteIds = selectedSites.map(site => site.id)
                params["site"] = siteIds
            }
            else if (selectedCompanies.length > 0) {
                const compIds = selectedCompanies.map(comp => comp.id)
                params["comp"] = compIds
            }
            // more filters
            if (selectedBirds.length > 0) {
                const birdIds = selectedBirds.map(bird => bird.id)
                params["annotations"] = birdIds;
            }
            params['has_video'] = hasVideo;
            params['sort_direction'] = sortDirection;
            params['sort_column'] = sortColumn;
            params['camera_mode'] = cameraMode;
            const fromDate = date[0].getFullYear() + "-" + (date[0].getMonth() + 1) + "-" + date[0].getDate()
            const toDate = date[1].getFullYear() + "-" + (date[1].getMonth() + 1) + "-" + date[1].getDate()
            console.log(fromDate, "----", toDate)
            params["from"] = fromDate
            params["to"] = toDate
            params["eventtypes"] = selectedTypes
        }
        const requestRoute = process.env.REACT_APP_API_URL + "events/get-next-prev"
        axios.get(requestRoute, {
            headers: { Authorization: `Bearer ${auth.token}` },
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then(response => {

            this.setState({
                nextEventId: response.data.next,
                prevEventId: response.data.prev,
            });
        }).catch(error => {
            console.log("Got error on get next previous events")
            console.log(error.response)

        });

    }
    /**
     * opens the modal with the clip or the image
     * @param {object} event 
     * @param {object} content 
     */
    handleOpenModal(event, content) {
        this.setState({
            showEventModal: true,
            isConfirmDelete: content == 'delete'
        });
    }

    /**
     * closes the modal
     */
    handleCloseModal() {
        this.setState({ showEventModal: false });
    }

    /**
     * handles the yes answer on the delete modal. It makes a delete request
     * to the backend
     */
    handleDelete() {
        //delete
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        axios.delete(process.env.REACT_APP_API_URL + 'events/' + this.props.event.id, config)
            .then(response => {
                this.setState({ showEventModal: false });

                this.props.browserHistory.push(`/events/${this.props.event.camera_mode}`);
            })
            .catch(error => {
                this.setState({ showEventModal: false });
                // this.props.setAlert("danger", "Action not accepted.", false);
                this.props.browserHistory.push(`/events/${this.props.event.camera_mode}`);
            })

    }

    handlePrevNextEvent(newEventId) {
        this.props.browserHistory.push(`/events/${newEventId}/details`);
        window.location.reload();
    }

    /**
     * displays tables with information about the event
     */
    render() {
        const { isConfirmDelete, prevEventId, nextEventId, eventMedia } = this.state
        const event = this.props.event
        // const track = event.event_type === "deterrence" ? "BMS-Warning.mp3" : "-"
        const eventMediaBtn = <div className="col-4 text-left">
            <button className={`primaryBtn ${eventMedia === 'image' ? 'active' : ''}`} onClick={() => { this.handleEventMediaBtn('image') }}>{'Event Picture'}</button>
            <button className={`primaryBtn mx-2 ${eventMedia === 'video' ? 'active' : ''}`} onClick={() => { this.handleEventMediaBtn('video') }}>{'Event Video'}</button>
            <button className="deleteBtn" onClick={() => { this.handleOpenModal(event, 'delete') }} >{'Delete'}</button>
        </div>

        // previous and next buttons
        const nextEventBtn = <button className={`navBtn float-right ${nextEventId === 0 ? 'disabled' : ''}`} value={'Prev'} disabled={nextEventId === 0} onClick={() => { this.handlePrevNextEvent(nextEventId) }}  ><i className='arrow right'></i></button>;
        const prevEventBtn = <button className={`navBtn ${prevEventId === 0 ? 'disabled' : ''}`} value={'Prev'} disabled={prevEventId === 0} onClick={() => { this.handlePrevNextEvent(prevEventId) }}  ><i className='arrow left'></i></button>


        return (
            <div className='px-5' style={{ "marginTop": "10px", "padding": "5px", "width": "100%" }}>
                <div className='row'>
                    <div className='col-lg-6 col-sm-12 px-5'>
                        <div className=" event-detail-box my-3 ">
                            <table className='table events-table'>
                                <tbody>
                                    <tr>
                                        <td className="gradiant-btn"  >Customer Name</td>
                                        <td className="event-details-info">{event.company.name}</td>
                                        <td style={eventDetailsSpacer}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="gradiant-btn">Site Location</td>
                                        <td className="event-details-info ">{event.generator.farm.site.name}</td>
                                        <td style={eventDetailsSpacer}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="gradiant-btn" >Wind Farm Name</td>
                                        <td className="event-details-info border-0">{event.generator.farm.name}</td>
                                        <td style={eventDetailsSpacer}>&nbsp;</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className='col-lg-6 col-sm-12 px-5'>
                        <div className=" event-detail-box my-3 ">
                            <table className='table events-table'>
                                <tbody>
                                    <tr>
                                        <td className="gradiant-btn"  >WTG number</td>
                                        <td className="event-details-info">{event.generator.name}</td>
                                        <td style={eventDetailsSpacer}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="gradiant-btn">Camera</td>
                                        <td className="event-details-info ">{event.camera.name}</td>
                                        <td style={eventDetailsSpacer}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="gradiant-btn" >Time stamp</td>
                                        <td className="event-details-info">{event.detailed_timestamp}</td>
                                        <td style={eventDetailsSpacer}>&nbsp;</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        {prevEventBtn}
                    </div>
                    <div className="col-4">
                        <div className='row'>
                            <h2 className="" style={{ paddingLeft: "10px" }}>Event Details</h2></div>
                    </div>
                    <div className="col-4">
                        {nextEventBtn}
                        {auth.role_id < 2 && <input type='button' className="deleteBtn float-right mr-4 mt-2" value={'Delete'} onClick={() => this.handleOpenModal(event, 'delete')} />}

                    </div>
                </div>
                <div className='row no-gutters my-3'>
                    <div className="col-4">
                        <div className=" event-detail-box px-0">
                            <table className='table events-table'>
                                <tbody>
                                    <tr>
                                        <td className="gradiant-btn title">Event Type</td>
                                        <td style={eventDetailsSpacer} className={`border-0 box-${event.event_type}-arrow`}>&nbsp;</td>
                                        <td className={`border-0 box-${event.event_type}-arrow`}>{event.event_type}</td>
                                    </tr>
                                    <tr>
                                        <td className="title">Event Trigger</td>
                                        <td style={eventDetailsSpacer}>&nbsp;</td>
                                        <td className="event-details-info border-0">{event.event_trigger ?? 'Auto'}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>

                        <div className='my-2 event-detail-box'>
                            <table className='table events-table'>
                                <tbody>
                                    <tr>
                                        <td className="title" >Logs</td>
                                        <td className="border-0"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='event-detail-box'>
                            <table className='table events-table'>
                                <tbody>
                                    <tr>
                                        <td className="title" >Auto Crop</td>
                                        <td className="border-0">Image</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div className="col-4">
                        <div className='mx-3 event-detail-box'>
                            <table className='table events-table'>
                                <tbody>
                                    <tr>
                                        <td className="title" >Individuals</td>
                                        <td className="border-0">1</td>
                                    </tr>
                                    <tr>
                                        <td className="title" >Bird Size</td>
                                        <td className="border-0">22</td>
                                    </tr>
                                    <tr>
                                        <td className="title" >Bird Classification</td>
                                        <td className="border-0"><EventAnnotationV1 object={event.object} site={event.generator.farm.site} key={`${Math.random()}`} /></td>
                                    </tr>
                                    <tr>
                                        <td className="title" >Flight Behaviour</td>
                                        <td className="border-0">4444</td>
                                    </tr>
                                    <tr>
                                        <td className="title" >True Positive</td>
                                        <td className="border-0">55555</td>
                                    </tr>
                                    <tr>
                                        <td className="title" >&nbsp;</td>
                                        <td className="border-0">&nbsp;</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div className="col-4">
                        <div className=' event-detail-box'>
                            <table className='table events-table'>
                                <tbody>
                                    <tr>
                                        <td className="title" >Wind Speed</td>
                                        <td className="border-0">{event.wind_speed ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="title" >Wind Direction</td>
                                        <td className="border-0">{event.wind_direction ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="title" >Nacelle Position</td>
                                        <td className="border-0">{event.nacelle_position ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="title" >Rotor Speed</td>
                                        <td className="border-0">{event.rotor_speed ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="title" >WTG Status</td>
                                        <td className="border-0">{event.wts_status ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="title" >Weather Conditions</td>
                                        <td className="border-0">{event.wind_speed ?? '-'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

                <div className="row my-3">
                    <div className='col-12 text-center'>
                        <div className='event-detail-box'>
                            <h2 className="" style={{ paddingLeft: "10px" }}>Multimedia</h2>
                            {eventMedia === 'image' && <ImagePreviewV1 imageUrl={event.image_url} location={event.location} >
                                {eventMediaBtn}
                            </ImagePreviewV1>}
                            {eventMedia === 'video' && <ClipPreviewV1 clips={event.clips}>{eventMediaBtn}</ClipPreviewV1>}
                        </div>
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-6">
                        {prevEventBtn}
                    </div>

                    <div className="col-6">
                        {nextEventBtn}
                    </div>
                </div>
                <Modal
                    isOpen={this.state.showEventModal}
                    onRequestClose={this.handleCloseModal}
                    style={isConfirmDelete ? modalStyleConfirmDelete : modalStyle}
                >

                    {isConfirmDelete && <div className="card">
                        <div className="card-body" style={modalCard}>
                            <h5 className="card-title text-center">Are you sure?</h5>
                            Are you sure you want to delete Event #<strong>{event.id}</strong>?
                            <div className="text-center" style={modalButtons}>
                                <a href="#" onClick={this.handleDelete} className="btn btn-primary">Yes</a>
                                <span style={spanStyle}></span>
                                <a href="#" onClick={this.handleCloseModal} className="btn btn-danger bg-danger border-danger">Cancel</a>
                            </div>
                        </div>
                    </div>}
                </Modal>
            </div>

        )
    }
}
const normalBreak = {
    wordBreak: "normal",
    backgroundColor: "#6ABBF1"
}

const wordBreak = {
    wordBreak: "break-word"
}
const eventDetailsSpacer = {
    width: "5%",
    border: "0px"
}
const modalStyleConfirmDelete = {
    content: {
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        borderStyle: "none",
        padding: "0 0"
    },
    overlay: { zIndex: 10000 },
}

const modalButtons = {
    padding: "4% 0 2%"
}
const modalStyle = {
    content: {
        padding: "0 0",
    },
    overlay: { zIndex: 10000 },
}
const spanStyle = {
    margin: "7px"
}
const modalCard = {
    // padding: "25px 90px",
    // borderStyle: "none"
    border: "1px solid #dde7f1"
}
export default (DetailsTable);
