import React, { Component } from 'react'
import axios from 'axios'
import Spinner from '../../Spinner'
import { smoothToTop } from '../../helpers/misc'
import auth from '../../helpers/auth'
import Modal from 'react-modal'

import DetailsTable from './DetailsTable'
import ImagePreview from './ImagePreview'
import ClipPreview from './ClipPreview'
import EventAnnotation from './EventAnnotation'
import ToBeAnnotatedCheckbox from './ToBeAnnotatedCheckBox'
import CoefsModal from './CoefsModal'


export class EventDetails extends Component {
    /**
     * Displays the details of the event page
     */
    state = {
        eventID: this.props.match.params.id,
        event: {},
        spinner: true,
        error: false,
        errorLog: "",
        event_spin: false,
        coefs_modal: false
    }
    /**
     * gets the event from the database
     */
    componentDidMount() {
        smoothToTop();
        document.title = "BMS App - Event " + this.state.eventID
        const eid = this.state.eventID
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        axios.get(process.env.REACT_APP_API_URL + 'events/' + eid, config)
            .then(response => {
                let event = response.data
                event.clips.map(clip => clip.visualised = { path: "", isComplete: false, onRequest: false })
                this.setState({
                    event: event,
                    spinner: false
                })
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    spinner: false,
                    error: true,
                    errorLog: error.response.data.error,
                })
            });
    }

    handleOpenModal = () => {
        this.setState({ coefs_modal: true })
    }

    handleCloseModal = () => {
        this.setState({ coefs_modal: false })
    }


    ToggleToBeAnnotated = () => {
        console.log("toggle toggle")
        this.setState({ event_spin: true })
        const eid = this.state.eventID
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        axios.post(process.env.REACT_APP_API_URL + 'events/' + eid + '/toggle/toBeAnnotated', {}, config)
            .then(response => {
                let event = this.state.event
                event.toBeAnnotated = !event.toBeAnnotated
                this.setState({ event: event, event_spin: false })
            })
            .catch(error => {
                console.log(error)
                this.setState({ event_spin: false })

            });
    }


    /**
     * Renders the event details page
     */
    render() {
        Modal.setAppElement("#root")
        const { spinner, eventID, event, event_spin, coefs_modal } = this.state
        const { error, errorLog } = this.state

        const canAnnotate = (auth.role_id < 3 || auth.role_id === 4) // allowing developers, admins, clientAdmins and Ornithologists.        
        if (spinner)
            return (
                <Spinner />
            )

        let toBeannotated = <div />
        if (auth.role_id == 0)
            toBeannotated =
                <div style={{ float: "right" }}>
                    <ToBeAnnotatedCheckbox event_id={event.id} checked={event.toBeAnnotated} spinner={event_spin} ToggleToBeAnnotated={() => this.ToggleToBeAnnotated()} />
                </div>

        let header, body
        header =
            <div style={{ display: "inline" }}>
                <h1 className="my-title"> Event <strong># {eventID}</strong> {toBeannotated}</h1>
                <hr className="my-Hr" />
            </div>
        if (error) {
            body = <p className="text-center">{errorLog}</p>
        }
        else {
            body =
                <div>

                    {canAnnotate && <EventAnnotation object={event.object} site={event.generator.farm.site} />}
                    <DetailsTable event={event} browserHistory={this.props.history} />
                    {auth.role_id == 0 && <button className="btn btn-link" onClick={this.handleOpenModal} style={{ "float": "right" }} > Check coefs </button>}
                    <div style={{ marginBottom: "300px" }} />
                </div>
        }


        return (
            <div>
                < Modal
                    isOpen={coefs_modal}
                    onRequestClose={this.handleCloseModal}
                    style={modalStyle}
                    shouldReturnFocusAfterClose={false}
                >
                    <div className="text-center">
                        <CoefsModal coefs={event.configurations} />
                        <button className="btn btn-danger" onClick={this.handleCloseModal} >Close</button>
                    </div>
                </Modal >
                {header}
                {body}
            </div>

        )
    }
}

const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: { zIndex: 20000 }
}


export default EventDetails