import React, { Component } from 'react'

export class GeneratorActionTable extends Component {
    /**
     * displays table with information about the generator
     */
    render() {
        const generator = this.props.generator
        return (
            <div style={{ "marginTop": "10px", "width": "100%" }}>
                <h2 className="my-title2">Details</h2>
                <table className="actionsTable">
                    <tbody>
                        <tr>
                            <th className="actions">ACTIONS</th>
                            <th className="operations"></th>
                            <th className="status">CURRENT STATUS</th>
                            <th className="logs">LOGS</th>
                        </tr>
                        <tr>
                            <td>System Enable / Disasble</td>
                            <td ><img src="/generator-actions/on_button.png" width="111" height="66" /></td>
                            <td >Active</td>
                            <td >&nbsp;</td>
                        </tr>
                        <tr>
                            <td>WTG Pause Test</td>
                            <td ><img src="/generator-actions/power_button.png" width="74" height="74" /></td>
                            <td >Available</td>
                            <td >&nbsp;</td>
                        </tr>
                            <tr>
                            <td >Play Sound</td>
                            <td ><img src="/generator-actions/power_button.png" width="74" height="74" /></td>
                            <td >Available</td>
                            <td >&nbsp;</td>
                        </tr>
                        <tr>
                            <td>Change Volume</td>
                            <td ><img src="/generator-actions/volume.png" width="375" height="62" /></td>
                            <td >100%</td>
                            <td >Done</td>
                        </tr>
                            <tr>
                            <td >Reboot Cabinet</td>
                            <td ><img src="/generator-actions/power_button.png" width="74" height="74" /></td>
                            <td >Available</td>
                            <td >Done</td>
                        </tr>
                        <tr>
                            <td>Start PC</td>
                            <td ><img src="/generator-actions/power_button_unavailable.png" width="74" height="74" /></td>
                            <td >Unavailable</td>
                            <td >&nbsp;</td>
                        </tr>
                            <tr>
                            <td >Water Spraying</td>
                            <td ><img src="/generator-actions/on_button.png" width="111" height="66" /></td>
                            <td >Active</td>
                            <td >Connection with PLC unavailable</td>
                        </tr>
                        <tr>
                            <td>Manual Spraying</td>
                            <td ><img src="/generator-actions/power_button.png" width="74" height="74" /></td>
                            <td >&nbsp;</td>
                            <td >&nbsp;</td>
                        </tr>
                            <tr>
                            <td >Spraying Automation</td>
                            <td ><img src="/generator-actions/set_button.png" width="111" height="66" /></td>
                            <td >5 sec / every 7 days</td>
                            <td >&nbsp;</td>
                        </tr>
                        <tr>
                            <td>Tank Level</td>
                            <td ><img src="/generator-actions/water-tank.png" width="375" height="51" /></td>
                            <td >100%</td>
                            <td >&nbsp;</td>
                        </tr>
                        <tr>
                        <td >Refill Tank</td>
                        <td ><img src="/generator-actions/refill_button.png" width="111" height="66" /></td>
                        <td >&nbsp;</td>
                        <td >&nbsp;</td>
                    </tr>
                </tbody>
            </table>
            </div>
        )
    }
}
const normalBreak = {
    wordBreak: "normal",
    backgroundColor: "#2A4B6E",
    color: "#E4BA06",
    borderColor: "#2A4B6E",
}

const wordBreak = {
    wordBreak: "break-word"
}

const boldStyle = {
    fontWeight: 'bold'
}
export default GeneratorActionTable