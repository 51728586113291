import React, { Component } from 'react'
import Modal from 'react-modal'
import { Multiselect } from 'multiselect-react-dropdown';
import { EventsTable } from '../EventsTable'
import { DateRangePicker } from '../DateRangePicker'

import auth from '../helpers/auth'
import { smoothToTop } from '../helpers/misc'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import qs from 'qs';

import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// import { FormControl } from '@material-ui/core'; //tried this because there was an import error, got fixed and reversed it
import FormLabel from '@material-ui/core/FormLabel';

import { EventsBar } from '../charts/EventsBar';
import { DetectionBirdsBar } from '../charts/DetectionBirdsBar';
import StatusPie from '../charts/StatusPie';
import Reports from '../pages/Reports'
import LittleSpinner from '../LittleSpinner';
import { Radio, Select, MenuItem } from '@material-ui/core';
import { withRouter } from 'react-router-dom'

axios.defaults.headers.common['ui-server-base'] = 'irida';
class Events extends Component {
    /**
     * Renders the page that searches and shows the events.
     */

    // intialitation of the Refs of the multiselect inputs.
    multiselectRefCompanies = React.createRef();
    multiselectRefSites = React.createRef();
    multiselectRefFarms = React.createRef();
    multiselectRefGenerators = React.createRef();
    multiselectRefCameras = React.createRef();
    multiselectRefBirds = React.createRef();
    defaultSortDirection = 'desc'
    defaultSortColumn = 'timestamp'
    defaultCameraMode = this.props.mode
    state = {
        alert: false,
        date: [new Date(), new Date()],
        showDetails: false,

        sitesDisabled: true,
        farmsDisabled: true,
        generatorsDisabled: true,
        camerasDisabled: true,
        optionData: [],
        selectedCompanies: [],
        selectedSites: [],
        selectedFarms: [],
        selectedGenerators: [],
        selectedCameras:[],
        tableSpinner: false,
        showModal: false,
        events: [],
        totalEvents: 0,
        pagingControl: {
            pageSize: 20,
            currentPage: 0,
            lastPage: 0
        },
        statistics: {
            detections: 0,
            deterrences: 0,
            shutdowns: 0,
            detection_birds: [],
            extra_event_types: {}
        },
        eventTypes: {
            detection: true,
            deterrence: true,
            shutdown: true
        },
        extraEventTypes:{},
        hasVideo: true,
        birdsData: [],
        selectedBirds: [],
        sortDirection: this.defaultSortDirection,
        sortColumn: this.defaultSortColumn,
        cameraMode: this.defaultCameraMode
        
    }

    /**
     * Opens the modal that contains the image or the clip of the event
     */
    handleOpenModal() {
        this.setState({ showModal: true });
    }
    /**
     * Closes the modal that contains the image or the clip of the event
     */
    handleCloseModal() {
        this.setState({ showModal: false });
    }
    /**
     * Removes all the selected items of the companis' multiselect
     */
    resetCompaniesValues() {
        this.multiselectRefCompanies.current.resetSelectedValues();
        this.resetSitesValues()
        let newArray = this.state.optionData
        newArray.map(comp => comp['children'] = [])
        this.setState({
            // sitesDisabled: auth.role_id > 0,
            farmsDisabled: true,
            generatorsData: true,
            selectedCompanies: [],
            optionData: newArray
        })
    }
    /**
     * Removes all the selected items of the sites' multiselect
     */
    resetSitesValues() {
        this.multiselectRefSites.current.resetSelectedValues();
        this.resetFarmsValues()
        this.resetGeneratorsValues()
        this.resetCamerasValues();
        let newArray = this.state.optionData
        newArray.map(comp => comp['children'].map(site => site['children'] = []))
        this.setState({
            farmsDisabled: true,
            selectedSites: [],
            optionData: newArray
        })
    }
    /**
     * Removes all the selected items of the farms' multiselect
     */
    resetFarmsValues() {
        this.multiselectRefFarms.current.resetSelectedValues();
        this.resetGeneratorsValues()
        this.resetCamerasValues();
        let newArray = this.state.optionData
        newArray.map(comp => comp['children'].map(site => site['children'].map(farm => farm['children'] = [])))
        this.setState({
            generatorsDisabled: true,
            selectedFarms: [],
            optionData: newArray
        })
    }
    /**
     * Removes all the selected items of the generators' multiselect
     */
    resetGeneratorsValues() {
        this.multiselectRefGenerators.current.resetSelectedValues();
        this.resetCamerasValues();
        let newArray = this.state.optionData
        newArray.map(comp => comp['children'].map(site => site['children'].map(farm => farm['children'].map( gen => gen['children'] = []))))
        this.setState({
            camerasDisabled: true,
            selectedGenerators: [],
            optionData: newArray
        })
    }
    /**
     * Removes all the selected items of the cameras' multiselect
     */
    resetCamerasValues() {
        this.multiselectRefCameras.current.resetSelectedValues();
        this.setState({
            selectedCameras: []
        })
    }

    /**
     * Removes all the selected items of the birds' multiselect
     */
    resetBirdsValues() {
        this.multiselectRefBirds.current.resetSelectedValues();
        this.setState({
            selectedBirds: []
        })
    }    
    /**
     * Removes all the selected items of the all multiselects
     */
    resetAll() {
        if (auth.role_id <= 1)
            this.resetCompaniesValues()
        this.resetSitesValues()
        this.resetFarmsValues()
        this.resetGeneratorsValues()
        this.resetCamerasValues();
        this.setState({
            events: [],
            totalEvents: 0,
            pagingControl: {
                pageSize: 20,
                currentPage: 0,
                lastPage: 0
            },
            showDetails: false,
            statistics: {
                detections: 0,
                deterrences: 0,
                shutdowns: 0,
                detection_birds: [],
                extra_event_types: {}
            }
        });
        this.resetSearchInLocalStorage();
        // search reset button resets more filters also
        this.resetMoreFilter();
    }
    /**
     * Displayes an alert on the top of the view
     * @param {string} type the type of the alert
     * @param {string} msg The message of the alert
     */
    setAlert = (type, msg, fetch = false) => {
        this.setState({
            alert: true,
            alertClass: "alert alert-" + type + " text-center",
            alertMsg: msg,
        })
        smoothToTop();
        setTimeout(() => {
            this.setState({
                alert: false,
                alertClass: '',
                alertMsg: '',
            });
            if (fetch) {
                this.search()
            }

        }, 3000)
    }
    /**
     * Makes the request with the selected values, and then downloads the created exel
     */
    downloadReport = () => {
        const { eventTypes, date } = this.state
        const { selectedGenerators, selectedFarms, selectedSites, selectedCompanies, selectedCameras, selectedBirds, cameraMode, hasVideo, sortDirection, sortColumn, extraEventTypes  } = this.state
        const eventsChecked = this.getExtraEventCheckedCount();
        let params = {}
        if (!eventTypes['detection'] && !eventTypes['deterrence'] && !eventTypes['shutdown'] && 0 === eventsChecked) {
            this.setAlert("warning", "Please, select an event-type")
            return
        }
        let selectedTypes = []
        if (eventTypes['detection'])
            selectedTypes.push('detection')
        if (eventTypes['deterrence'])
            selectedTypes.push('deterrence')
        if (eventTypes['shutdown'])
            selectedTypes.push('shutdown')
        // extra event types 
        Object.keys(extraEventTypes).map((extraEventType, extraEventTypeIndex) => {
            if(extraEventTypes[extraEventType]['checked']){
                selectedTypes.push(extraEventType);
            }
        })

        if (selectedCameras.length > 0) {
            const camIds = selectedCameras.map(cam => cam.id)
            params["cam"] = camIds
        } else if (selectedGenerators.length > 0) {
            const genIds = selectedGenerators.map(gen => gen.id)
            params["gen"] = genIds
        }
        else if (selectedFarms.length > 0) {
            const farmsIds = selectedFarms.map(farm => farm.id)
            params["farm"] = farmsIds
        }
        else if (selectedSites.length > 0) {
            const siteIds = selectedSites.map(site => site.id)
            params["site"] = siteIds
        }
        else if (selectedCompanies.length > 0) {
            const compIds = selectedCompanies.map(comp => comp.id)
            params["comp"] = compIds
        } else {
            this.setAlert("warning", "Please, fill the reaquest fields.")
            return
        }
        const fromDate = date[0].getFullYear() + "-" + (date[0].getMonth() + 1) + "-" + date[0].getDate()
        const toDate = date[1].getFullYear() + "-" + (date[1].getMonth() + 1) + "-" + date[1].getDate()
        const requestRoute = process.env.REACT_APP_API_URL + "events/report"
        console.log(fromDate, "----", toDate)
        params["from"] = fromDate
        params["to"] = toDate
        params["eventtypes"] = selectedTypes
        
        // more filters
        if (selectedBirds.length > 0) {
            const birdIds = selectedBirds.map(bird => bird.id)
            params["annotations"] = birdIds;
        }
        params['has_video'] = hasVideo;
        params['sort_direction'] = sortDirection;
        params['sort_column'] = sortColumn;
        params['camera_mode'] = cameraMode;

        console.log("requesting to :: " + requestRoute)
        this.handleOpenModal()
        axios.get(requestRoute, {
            headers: { Authorization: `Bearer ${auth.token}` },
            responseType: 'blob',
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            let nowDate = new Date();
            let date = nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getDate();
            let filename = 'BMS-Report-' + date + '.xlsx'
            link.setAttribute('download', filename); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            this.handleCloseModal()
        })
            .catch(error => {
                console.log("Got error on report request")
                console.log(error)
                this.handleCloseModal()
            })
        // this.handleCloseModal()
    }

    /**
     * Enalbles the Graphs area in the bottom of the page
     */
    showDetails = () => {
        this.setState({
            showDetails: true
        })
    }
    /**
     * Synchronises the new date with the state
     * @param {Date} date 
     */
    onChangeDate = (date) => {
        console.log("date-change: ", date)
        this.setState({ date })
    }
    /**
     * Requests the page with the pageId from the backend with the given search inputs
     * @param {int} pageId 
     */
    changePage = (pageId) => {
        const { eventTypes, extraEventTypes, date } = this.state
        const { selectedGenerators, selectedFarms, selectedSites, selectedCompanies, selectedCameras, selectedBirds, hasVideo, sortDirection, sortColumn, cameraMode } = this.state
        const eventsChecked = this.getExtraEventCheckedCount();
        let params = {}
        if (!eventTypes['detection'] && !eventTypes['deterrence'] && !eventTypes['shutdown'] && 0 === eventsChecked) {
            this.setAlert("warning", "Please, select an event-type")
            return
        }
        let selectedTypes = []
        if (eventTypes['detection'])
            selectedTypes.push('detection')
        if (eventTypes['deterrence'])
            selectedTypes.push('deterrence')
        if (eventTypes['shutdown'])
            selectedTypes.push('shutdown')
        // extra event types 
        Object.keys(extraEventTypes).map((extraEventType, extraEventTypeIndex) => {
            if(extraEventTypes[extraEventType]['checked']){
                selectedTypes.push(extraEventType);
            }
        })
        if (selectedCameras.length > 0) {
            const camIds = selectedCameras.map(cam => cam.id)
            params["cam"] = camIds
        }    
        else if (selectedGenerators.length > 0) {
            const genIds = selectedGenerators.map(gen => gen.id)
            params["gen"] = genIds
        }
        else if (selectedFarms.length > 0) {
            const farmsIds = selectedFarms.map(farm => farm.id)
            params["farm"] = farmsIds
        }
        else if (selectedSites.length > 0) {
            const siteIds = selectedSites.map(site => site.id)
            params["site"] = siteIds
        }
        else if (selectedCompanies.length > 0) {
            const compIds = selectedCompanies.map(comp => comp.id)
            params["comp"] = compIds
        } else {
            this.setAlert("warning", "Please, fill the request fields.")
            return
        }
        // more filters
        if (selectedBirds.length > 0) {
            const birdIds = selectedBirds.map(bird => bird.id)
            params["annotations"] = birdIds;
        }
        params['has_video'] = hasVideo;
        params['sort_direction'] = sortDirection;
        params['sort_column'] = sortColumn;
        params['camera_mode'] = cameraMode;
        const fromDate = date[0].getFullYear() + "-" + (date[0].getMonth() + 1) + "-" + date[0].getDate()
        const toDate = date[1].getFullYear() + "-" + (date[1].getMonth() + 1) + "-" + date[1].getDate()
        params["from"] = fromDate
        params["to"] = toDate
        params["page"] = pageId
        params["eventtypes"] = selectedTypes
        localStorage.setItem("search_event_page", pageId);
        const requestRoute = process.env.REACT_APP_API_URL + "events/search"
        this.setState({
            tableSpinner: true
        })
        axios.get(requestRoute, {
            headers: { Authorization: `Bearer ${auth.token}` },
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then(response => {
            const myPagingControl = {
                currentPage: response.data.current_page,
                lastPage: response.data.last_page,
            }
            let events = response.data.data
            // events.map(event => event['generator'] = this.state.selectedGenerators.filter(gen => gen.id === event.generator_id)[0])
            this.setState({
                tableSpinner: false,
                totalEvents: response.data.total,
                events: events,
                pagingControl: myPagingControl
            })
        })
            .catch(error => {
                // this.setAlert("danger", "Errorfull request")
                console.log("Got error on search request")
                console.log(error.response)
                this.setState({
                    tableSpinner: false,
                })
            })
    }
    storeSearchParamsInLocalStorage(pageId) {
        const { eventTypes, extraEventTypes, date } = this.state
        const { selectedCameras, selectedGenerators, selectedFarms, selectedSites, selectedCompanies, hasVideo, sortDirection, sortColumn, cameraMode, selectedBirds } = this.state
        localStorage.setItem('searched', true);
        localStorage.setItem('event_types', JSON.stringify(eventTypes));
        localStorage.setItem('extra_event_types', JSON.stringify(extraEventTypes));
        localStorage.setItem('search_date', JSON.stringify(date));
        localStorage.setItem('selected_generators', JSON.stringify(selectedGenerators));
        localStorage.setItem('selected_cameras', JSON.stringify(selectedCameras));
        localStorage.setItem('selected_farms', JSON.stringify(selectedFarms));
        localStorage.setItem('selected_sites', JSON.stringify(selectedSites));
        localStorage.setItem('selected_companies', JSON.stringify(selectedCompanies));
        let pageIdToSave = (pageId) ? pageId : 1;
        localStorage.setItem("search_event_page", pageIdToSave);

        // more filters
        localStorage.setItem('has_video', hasVideo);
        localStorage.setItem('sort_direction', sortDirection);
        localStorage.setItem('sort_column', sortColumn);
        // localStorage.setItem('camera_mode', cameraMode);
        localStorage.setItem('selected_birds', JSON.stringify(selectedBirds));

    }
    resetSearchInLocalStorage() {
        localStorage.removeItem('searched');
        localStorage.removeItem('event_types');
        localStorage.removeItem('extra_event_types');
        localStorage.removeItem('search_date');
        localStorage.removeItem('selected_cameras');
        localStorage.removeItem('selected_generators');
        localStorage.removeItem('selected_farms');
        localStorage.removeItem('selected_sites');
        localStorage.removeItem('selected_companies');
        localStorage.removeItem("search_event_page");

        // more filters
        localStorage.removeItem('has_video');
        localStorage.removeItem('sort_direction');
        localStorage.removeItem('selected_birds');
    }
    doSearchOnpageLoad() {
        const searched = localStorage.getItem('searched');
        if (searched) {
            const eventTypes = JSON.parse(localStorage.getItem('event_types'));
            const eventExtraTypes = JSON.parse(localStorage.getItem('extra_event_types'));
            const savedDate = JSON.parse(localStorage.getItem('search_date'));
            let date = [];
            savedDate.map(date_tmp => {
                date.push(new Date(date_tmp));
            })
            const selectedGenerators = JSON.parse(localStorage.getItem("selected_generators")) || [];
            const selectedCameras = JSON.parse(localStorage.getItem("selected_cameras")) || [];
            const selectedFarms = JSON.parse(localStorage.getItem("selected_farms")) || [];
            const selectedSites = JSON.parse(localStorage.getItem("selected_sites")) || [];
            const selectedCompanies = JSON.parse(localStorage.getItem("selected_companies")) || [];
            const page = localStorage.getItem("search_event_page") || '';

            // more filters
            const selectedBirds = JSON.parse(localStorage.getItem("selected_birds")) || [];
            const hasVideo = localStorage.getItem("has_video") ? (localStorage.getItem("has_video") === 'true') : true;
            const sortDirection =  localStorage.getItem("sort_direction") || this.defaultSortDirection;
            const sortColumn =  localStorage.getItem("sort_column") || this.defaultSortColumn;
            const cameraMode =  this.defaultCameraMode;

            this.setState({ eventTypes, eventExtraTypes, date, selectedGenerators, selectedFarms, selectedSites, selectedCompanies, selectedCameras, selectedBirds, hasVideo, sortDirection, sortColumn, cameraMode }, () => {
                this.search(page);
            })

            //
        }
    }
    getExtraEventCheckedCount(){
        const { extraEventTypes } = this.state;
        let eventsChecked = 0;
        // extra event types 
        Object.keys(extraEventTypes).map((extraEventType, extraEventTypeIndex) => {
            if(extraEventTypes[extraEventType]['checked']){
                eventsChecked++;
            }
        });
        return eventsChecked;
    }
    /**
     * Parses the given inputs, creates the request structure, and makes the request on the backend. On
     * the response, it fills the eventtable
     */
    search = (pageId) => {
        console.log(pageId);
        const { eventTypes, date } = this.state
        const { selectedCameras, selectedGenerators, selectedFarms, selectedSites, selectedCompanies, selectedBirds, hasVideo, sortDirection, sortColumn, cameraMode, extraEventTypes } = this.state
        const eventsChecked = this.getExtraEventCheckedCount();
        let params = {}
        if (!eventTypes['detection'] && !eventTypes['deterrence'] && !eventTypes['shutdown'] && 0 === eventsChecked) {
            this.setAlert("warning", "Please, select an event-type")
            return
        }
        let selectedTypes = []
        if (eventTypes['detection'])
            selectedTypes.push('detection')
        if (eventTypes['deterrence'])
            selectedTypes.push('deterrence')
        if (eventTypes['shutdown'])
            selectedTypes.push('shutdown')

        // extra event types 
        Object.keys(extraEventTypes).map((extraEventType, extraEventTypeIndex) => {
            if(extraEventTypes[extraEventType]['checked']){
                selectedTypes.push(extraEventType);
            }
        })
        if (selectedCameras.length > 0) {
            const camIds = selectedCameras.map(cam => cam.id)
            params["cam"] = camIds
        }
        else if (selectedGenerators.length > 0) {
            const genIds = selectedGenerators.map(gen => gen.id)
            params["gen"] = genIds
        }
        else if (selectedFarms.length > 0) {
            const farmsIds = selectedFarms.map(farm => farm.id)
            params["farm"] = farmsIds
        }
        else if (selectedSites.length > 0) {
            const siteIds = selectedSites.map(site => site.id)
            params["site"] = siteIds
        }
        else if (selectedCompanies.length > 0) {
            const compIds = selectedCompanies.map(comp => comp.id)
            params["comp"] = compIds
        } else {
            this.setAlert("warning", "Please, fill the request fields.")
            return
        }
        // more filters
        if (selectedBirds.length > 0) {
            const birdIds = selectedBirds.map(bird => bird.id)
            params["annotations"] = birdIds;
        }
        params['has_video'] = hasVideo;
        params['sort_direction'] = sortDirection;
        params['sort_column'] = sortColumn;
        params['camera_mode'] = cameraMode;
        const fromDate = date[0].getFullYear() + "-" + (date[0].getMonth() + 1) + "-" + date[0].getDate()
        const toDate = date[1].getFullYear() + "-" + (date[1].getMonth() + 1) + "-" + date[1].getDate()
        console.log(fromDate, "----", toDate)
        params["from"] = fromDate
        params["to"] = toDate
        params["page"] = (pageId) ? pageId : 1;
        params["eventtypes"] = selectedTypes
        // save the search params to localstorage
        this.storeSearchParamsInLocalStorage(pageId);
        const requestRoute = process.env.REACT_APP_API_URL + "events/search"

        this.setState({
            tableSpinner: true
        })
        axios.get(requestRoute, {
            headers: { Authorization: `Bearer ${auth.token}` },
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then(response => {
            // this.setAlert("success", "Successful request")
            const myPagingControl = {
                currentPage: response.data.current_page,
                lastPage: response.data.last_page,
            }
            let events = response.data.data
            events.map(event => event['generator'] = this.state.selectedGenerators.filter(gen => gen.id === event.generator_id)[0])
            this.setState({
                tableSpinner: false,
                totalEvents: response.data.total,
                events: events,
                pagingControl: myPagingControl,
                showDetails: false,
            })
            this.fetchStatistics()
            this.fetchAllData()
            // console.log("Response: ", response.data)
            // console.log("Events: ", response.data.data)

        })
            .catch(error => {
                // this.setAlert("danger", "Errorfull request")
                console.log("Got error on search request")
                console.log(error.response)
                this.setState({
                    tableSpinner: false,
                })
            })
    }
    /**
     * After a search request, this function is executed. It fetched some statistics
     * about the current search
     */
    fetchStatistics = () => {
        const { eventTypes, extraEventTypes, date } = this.state
        const { selectedCameras, selectedGenerators, selectedFarms, selectedSites, selectedCompanies, selectedBirds, hasVideo, sortDirection,  sortColumn, cameraMode } = this.state
        let params = {}
        let selectedTypes = []
        if (eventTypes['detection'])
            selectedTypes.push('detection')
        if (eventTypes['deterrence'])
            selectedTypes.push('deterrence')
        if (eventTypes['shutdown'])
            selectedTypes.push('shutdown')

        // extra event types 
        Object.keys(extraEventTypes).map((extraEventType, extraEventTypeIndex) => {
            if(extraEventTypes[extraEventType]['checked']){
                selectedTypes.push(extraEventType);
            }
        })

        if (selectedCameras.length > 0) {
            const camIds = selectedCameras.map(cam => cam.id)
            params["cam"] = camIds
        }
        else  if (selectedGenerators.length > 0) {
            const genIds = selectedGenerators.map(gen => gen.id)
            params["gen"] = genIds
        }
        else if (selectedFarms.length > 0) {
            const farmsIds = selectedFarms.map(farm => farm.id)
            params["farm"] = farmsIds
        }
        else if (selectedSites.length > 0) {
            const siteIds = selectedSites.map(site => site.id)
            params["site"] = siteIds
        }
        else if (selectedCompanies.length > 0) {
            const compIds = selectedCompanies.map(comp => comp.id)
            params["comp"] = compIds
        } else {
            this.setAlert("danger", "Unexpected action.")
            return
        }
        // more filters
        if (selectedBirds.length > 0) {
            const birdIds = selectedBirds.map(bird => bird.id)
            params["annotations"] = birdIds;
        }
        params['has_video'] = hasVideo;
        params['sort_direction'] = sortDirection;
        params['sort_column'] = sortColumn;
        params['camera_mode'] = cameraMode;

        const fromDate = date[0].getFullYear() + "-" + (date[0].getMonth() + 1) + "-" + date[0].getDate()
        const toDate = date[1].getFullYear() + "-" + (date[1].getMonth() + 1) + "-" + date[1].getDate()
        params["from"] = fromDate
        params["to"] = toDate
        params["eventtypes"] = selectedTypes
        const requestRoute = process.env.REACT_APP_API_URL + "events/statistics"
        axios.get(requestRoute, {
            headers: { Authorization: `Bearer ${auth.token}` },
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then(response => {
            // this.setAlert("success", "Successful request")
            const statistics = {
                detections: response.data.detections,
                deterrences: response.data.deterrences,
                shutdowns: response.data.shutdowns,
                detection_birds: response.data.detection_birds,
                extra_event_types: response.data.extra_event_types,
            }
            this.setState({
                statistics: statistics
            })
        })
            .catch(error => {
                // this.setAlert("danger", "Errorfull request")
                console.log("Got error on statistics request")
                console.log(error.response)
            })
    }
    /**
     * Calculates the active and the inactive generators from those that are set in the multiselect
     * input
     */
    getActiveCount() {
        let res = {}
        res["activeCount"] = this.state.selectedGenerators.filter(gen => gen.isActive === 1).length
        res["inactiveCount"] = this.state.selectedGenerators.filter(gen => gen.isActive === 0).length
        return res
    }

    // --------------------------------------------------------------------------------------------------------- //

    componentDidMount() {
        // smoothToTop() //Commented for "show more details" rerendering
        this.fetchInitialData()
        let mode = this.props.mode.toUpperCase();
        document.title = `BMS App - ${mode} Events`

        //set the first value from the defaults
    }

    /**
     * Fetches the inital data. If the user is admin, the companies are fetched
     * otherwise the sites.
     */
    fetchInitialData() {
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
         // get extra events types 
         axios.get(process.env.REACT_APP_API_URL + 'events/types', config)
         .then(response => {
             // let items = [];
             // response.data.map((item) => {
             //     items.push(item)
             // })
             // console.log("Initial data: ", response.data);
             // check for local storage
             const extraEventTypesLocal = JSON.parse(localStorage.getItem('extra_event_types'));
             let items = response.data;
             console.log('local event extra')
             console.log(extraEventTypesLocal);

             if( extraEventTypesLocal){
                 console.log(Object.keys(extraEventTypesLocal).length);
                 if(Object.keys(extraEventTypesLocal).length > 0 ){
                    // alert('hi')
                     Object.keys(items).map(item => {
                         if( !extraEventTypesLocal[item]['checked']){
                             items[item]['checked'] = false;
                         }
                     })
                 }
             }
             // console.log(items);
             this.setState({
                 extraEventTypes: items,
             }); 
             
         }).catch(error => {
             console.log("Error on fetching more or extra event types data")
             console.log(error)
         });

        // let route = auth.role_id > 1 ? "sites" : "companies-lite"
        let route = "companies-lite"
        axios.get(process.env.REACT_APP_API_URL + route, config)
            .then(response => {
                let items = [];
                response.data.map((item) => {
                    item['children'] = [];
                    items.push(item)
                })
                console.log("Initial data: ", items)
                this.setState({
                    optionData: items
                }, () => {
                    // search the form if already searched and user clicked back
                    this.doSearchOnpageLoad()
                })
                if (auth.role_id > 1) {
                    //fetch also the sites
                    let newSelectedCompanies = []
                    items.map(comp => {
                        this.fetchData('company', comp)
                        newSelectedCompanies.push(comp)
                    })
                    this.setState({
                        selectedCompanies: newSelectedCompanies
                    }, () => {
                        // search the form if already searched and user clicked back
                        this.doSearchOnpageLoad()
                    })
                }
            })
            .catch(error => {
                console.log("Error on fetching initial data")
                console.log(error)
            });

        // get birds data 
        axios.get(process.env.REACT_APP_API_URL + 'annotations', config)
            .then(response => {
                let items = [{'id': 'no_bird', category: 'No Bird'}];
                response.data.map((item) => {
                    items.push(item)
                })
                console.log("Initial data: ", items);
                
                this.setState({
                    birdsData: items,
                    // selectedBirds: items
                }); 
                // by default selects all birds
                
            }).catch(error => {
                console.log("Error on fetching birds data")
                console.log(error)
            });

       
    }
    /**
     * When the user selects an item from a category, then in the next category, the children of the item should be
     * fetched in order to be displayed as a optional values. This is what is happening in this page.
     * @param {String} category The category of the item (companies, sites, farms)
     * @param {Object} item The item
     */
    fetchData = (category, item) => {
        /*  
            ROUTES 
            /companies/{cid}/sites
            /sites/{sid}/farms
            /farms/{fid}/generators
             /generators/{gid}/cameras
        */
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        let route;
        if (category === "company") {
            route = "companies/" + item.id + "/sites"
        }
        else if (category === "site") {
            route = "sites/" + item.id + "/farms"
        }
        else if (category === "farm") {
            route = "farms/" + item.id + "/generators"
        } 
        else if (category === "generator") {
            route = "generators/" + item.id + "/cameras"
        }
        axios.get(process.env.REACT_APP_API_URL + route, config)
            .then(response => {
                if (response.data.length === 0) {
                    const childname = category === "company" ? "sites" : category === "site" ? "farms" : category === "farm" ? "generators" : category === "generator" ? "cameras" : "'?'"
                    this.setAlert("warning", "No " + childname + " are found for the " + category + ": " + item.name)
                    return
                }
                response.data.map((incomingItem) => {
                    incomingItem['view_name'] = item['name'] + ": " + incomingItem['name'] // <p><span style={spanStyle}>{item['name'] +":"}</span> {incomingItem['name']}</p>
                    incomingItem['children'] = []
                    item['children'].push(incomingItem)
                })
                let newArray;
                if (category === "company") {
                    //we got sites
                    const elementsIndex = this.state.optionData.findIndex(element => element.id === item.id)
                    newArray = [...this.state.optionData]
                    newArray[elementsIndex]['children'] = item['children']//newArray[elementsIndex] = {...newArray[elementsIndex], children: item['children']}
                }
                else if (category === "site") {
                    //we got farms
                    const elementsIndex = this.state.optionData.findIndex(element => element.id === item.company_id)
                    const elementsSitesIndex = this.state.optionData[elementsIndex]['children'].findIndex(element => element.id === item.id)
                    newArray = [...this.state.optionData]
                    newArray[elementsIndex]['children'][elementsSitesIndex]['children'] = item['children']  //{...newArray[elementsIndex]['children'][elementsSitesIndex], children: item['children']} 
                }
                else if (category === "farm") {
                    //we got generators
                    const elementsIndex = this.state.optionData.findIndex(element => element.id === item.company_id)
                    const elementsSitesIndex = this.state.optionData[elementsIndex]['children'].findIndex(element => element.id === item.site_id)
                    const elementsFarmsIndex = this.state.optionData[elementsIndex]['children'][elementsSitesIndex]['children'].findIndex(element => element.id === item.id)
                    newArray = [...this.state.optionData]
                    newArray[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex]['children'] = item['children'] //{...newArray[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex], children: item['children']} 
                }
                else if (category === "generator") {
                    //we got generators
                    const elementsIndex = this.state.optionData.findIndex(element => element.id === item.company_id)
                    const elementsSitesIndex = this.state.optionData[elementsIndex]['children'].findIndex(element => element.id === item.site_id)
                    const elementsFarmsIndex = this.state.optionData[elementsIndex]['children'][elementsSitesIndex]['children'].findIndex(element => element.id === item.farm_id)
                    const elementsGensIndex = this.state.optionData[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex]['children'].findIndex(element => element.id === item.id)
                    newArray = [...this.state.optionData]
                    newArray[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex]['children'][elementsGensIndex]['children'] = item['children'] //{...newArray[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex][elementsGensIndex]['children'], children: item['children']} 
                }
                console.log(newArray);
                this.setState({
                    optionData: newArray,
                }, () => {
                    console.log("New state: ", this.state)
                    this.checkDisables()
                })
            })
            .catch(error => {
                console.log("Error on fetching data")
                console.log(error)
            });
    }
    /**
     * When the user searches without to fullfil all the multiselectors, then the datatree of the 
     * selected item should be fetched and added. This is what this fucntion does. The datatree starts 
     * from the latest item category that is filled.
     */
    fetchAllData = () => {
        const { selectedCameras, selectedGenerators, selectedFarms, selectedSites, selectedCompanies } = this.state
        let params = {}
        if (selectedCameras.length > 0) {
            return
        }
        else if (selectedGenerators.length > 0) {
            const genIds = selectedGenerators.map(gen => gen.id)
            params["gen_ids"] = genIds
        }
        else if (selectedFarms.length > 0) {
            const farmsIds = selectedFarms.map(farm => farm.id)
            params["farm_ids"] = farmsIds
        }
        else if (selectedSites.length > 0) {
            const siteIds = selectedSites.map(site => site.id)
            params["site_ids"] = siteIds
        }
        else if (selectedCompanies.length > 0) {
            const compIds = selectedCompanies.map(comp => comp.id)
            params["comp_ids"] = compIds
        } else {
            this.setAlert("warning", "Please, fill the reaquest fields.")
            return
        }
        const requestRoute = process.env.REACT_APP_API_URL + "datatree"
        axios.get(requestRoute, {
            headers: { Authorization: `Bearer ${auth.token}` },
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then(response => {
            let results = response.data
            if (selectedGenerators.length > 0) {
                let upldatedSelectedGenerators = selectedGenerators
                let newSelectedCameras = selectedCameras
                upldatedSelectedGenerators.forEach(gen => gen['children'] = results.filter(f => gen.id === f.id)[0]['cameras'])
                results.map(gen => gen['cameras'].map(cam => newSelectedCameras.push(cam)))
                this.setState({
                    newSelectedGenerators: upldatedSelectedGenerators,
                    selectedCameras: newSelectedCameras
                })
            } else  if (selectedFarms.length > 0) {
                let upldatedSelectedFarms = selectedFarms
                let newSelectedGens = selectedGenerators
                let newSelectedCameras = selectedCameras
                upldatedSelectedFarms.forEach(farm => farm['children'] = results.filter(f => farm.id === f.id)[0]['generators'])
                results.map(farm => farm['generators'].map(gen => {
                    newSelectedGens.push(gen)
                    gen['children'] = gen['cameras'];
                    gen['children'].map(camera => {
                        newSelectedCameras.push(camera);
                    })
                }))
                this.setState({
                    newSelectedFarms: upldatedSelectedFarms,
                    selectedGenerators: newSelectedGens,
                    selectedCameras: newSelectedCameras
                })
            }
            else if (selectedSites.length > 0) {
                let updatedSites = selectedSites
                let newSelectedGens = selectedGenerators
                let newSelectedFarms = selectedFarms
                let newSelectedCameras = selectedCameras
                updatedSites.forEach(site => site['children'] = results.filter(s => site.id === s.id)[0]['farms'])
                results.map(site => site["farms"].map(farm => {
                    newSelectedFarms.push(farm)
                    farm['children'] = farm['generators']
                    farm['children'].map(gen => {
                        newSelectedGens.push(gen)
                        gen['children'] = gen['cameras'];
                        gen['children'].map(camera => {
                            newSelectedCameras.push(camera);
                        })
                    })
                }))
                this.setState({
                    selectedSites: updatedSites,
                    selectedGenerators: newSelectedGens,
                    selectedFarms: newSelectedFarms,
                    selectedCameras: newSelectedCameras
                })
            }
            else if (selectedCompanies.length > 0) {
                let updatedCompanies = selectedCompanies
                let newSelectedGens = selectedGenerators
                let newSelectedFarms = selectedFarms
                let newSelectedSites = selectedSites
                let newSelectedCameras = selectedCameras
                updatedCompanies.forEach(comp => comp['children'] = results.filter(c => comp.id === c.id)[0]['sites'])
                results.map(comp => {
                    comp['children'] = comp['sites']
                    comp['children'].map(site => {
                        newSelectedSites.push(site)
                        site['children'] = site['farms']
                        site['children'].map(farm => {
                            newSelectedFarms.push(farm)
                            farm['children'] = farm['generators']
                            farm['children'].map(gen => {
                                newSelectedGens.push(gen)
                                gen['children'] = gen['cameras'];
                                gen['children'].map(camera => {
                                    newSelectedCameras.push(camera);
                                })
                            })
                        })
                    })
                })
                this.setState({
                    selectedCompaines: updatedCompanies,
                    selectedGenerators: newSelectedGens,
                    selectedFarms: newSelectedFarms,
                    selectedSites: newSelectedSites,
                    selectedCameras: newSelectedCameras
                })
            }
        })
            .catch(error => {
                // this.setAlert("danger", "Errorfull request")
                console.log("Got error on loading data tree request")
                console.log(error.response)
            })
    }

    /**
     * Checks which of the multiselects should be disabled or not. 
     */
    checkDisables = () => {
        // console.log("CHECKING DISABLES")
        // const { optionData } = this.state
        // let mySitesDisabled = true
        // let myFarmsDisabled = true
        // let myGeneratorsDisabled = true

        // if (optionData.length > 0) {
        //     const sitesData = []            
        //     optionData.map( company => company['children'].map(site => sitesData.push(site)))
        //     const farmsData = []
        //     sitesData.map( site => site['children'].map(farm => farmsData.push(farm)))
        //     const generatorsData = []
        //     farmsData.map( farm => farm['children'].map(gen => generatorsData.push(gen)))
        //     if(sitesData.length > 0){
        //         // console.log("found sites")
        //         mySitesDisabled = false
        //         if( farmsData.length >0 ){
        //             // console.log("found farm")
        //             myFarmsDisabled = false
        //             if( generatorsData.length > 0 ){
        //                 // console.log("found generators")
        //                 myGeneratorsDisabled = false                        
        //             }
        //         }
        //     }
        //     this.setState({
        //         sitesDisabled: mySitesDisabled,
        //         farmsDisabled: myFarmsDisabled,
        //         generatorsDisabled: myGeneratorsDisabled
        //     })
        // }
        // else
        //     console.warn("company option DATA are empty")
        let { selectedCompanies, selectedSites, selectedFarms, selectedGenerators } = this.state
        let disables = {
            sitesDisabled: !selectedCompanies.length > 0,
            farmsDisabled: !selectedSites.length > 0,
            generatorsDisabled: !selectedFarms.length > 0,
            camerasDisabled: !selectedGenerators.length > 0
        }
        return disables
    }
    /**
     * Updates and syncs the selected item in the states onSelect event
     * @param {String} category 
     * @param {Array} selectedList 
     * @param {Object} selectedItem 
     */
    onSelect = (category, selectedList, selectedItem) => {
        if (category === 'camera') {
            let selected = this.state.selectedCameras;
            selected.push(selectedItem)
            this.setState({
                selectedCameras: selected,
            })
            return
        }
        this.fetchData(category, selectedItem)
        
       
        // until now the below is useless
        if (category === 'generator') {
            let selected = this.state.selectedGenerators;
            selected.push(selectedItem)
            this.setState({
                selectedGenerators: selected,
            })
        }
        else if (category === "company") {
            let selected = this.state.selectedCompanies;
            selected.push(selectedItem)
            this.setState({
                selectedCompanies: selected
            })
        }
        else if (category === "site") {
            let selected = this.state.selectedSites;
            selected.push(selectedItem)
            this.setState({
                selectedSites: selected
            })
        }
        else if (category === "farm") {
            let selected = this.state.selectedFarms;
            selected.push(selectedItem)
            this.setState({
                selectedFarms: selected
            })
        }
    }
    /**
     * Updates and syncs the selected item in the states onRemove event
     * @param {String} category 
     * @param {Array} selectedList 
     * @param {Object} selectedItem 
     */
    onRemove = (category, selectedList, removedItem) => {
        if (category === "company") {
            const elementsIndex = this.state.optionData.findIndex(element => element.id === removedItem.id)
            let newArray = [...this.state.optionData]
            newArray[elementsIndex]['children'] = []
            let newSelectedCompanies = this.state.selectedCompanies
            newSelectedCompanies = newSelectedCompanies.filter(comp => comp.id !== removedItem.id)
            this.setState({
                optionData: newArray,
                selectedCompanies: newSelectedCompanies
            }, () => {
                console.log("New state: ", this.state)
                // this.checkDisables()
            })
            this.resetSitesValues()
        }
        else if (category === "site") {
            const elementsIndex = this.state.optionData.findIndex(element => element.id === removedItem.company_id)
            const elementsSitesIndex = this.state.optionData[elementsIndex]['children'].findIndex(element => element.id === removedItem.id)
            let newArray = [...this.state.optionData]
            newArray = [...this.state.optionData]
            newArray[elementsIndex]['children'][elementsSitesIndex]['children'] = []
            let newSelectedSites = this.state.selectedSites
            newSelectedSites = newSelectedSites.filter(site => site.id !== removedItem.id)
            this.setState({
                optionData: newArray,
                selectedSites: newSelectedSites
            }, () => {
                console.log("New state: ", this.state)
                // this.checkDisables()
            })
            this.resetFarmsValues()
        }
        else if (category === "farm") {
            const elementsIndex = this.state.optionData.findIndex(element => element.id === removedItem.company_id)
            const elementsSitesIndex = this.state.optionData[elementsIndex]['children'].findIndex(element => element.id === removedItem.site_id)
            const elementsFarmsIndex = this.state.optionData[elementsIndex]['children'][elementsSitesIndex]['children'].findIndex(element => element.id === removedItem.id)
            let newArray = [...this.state.optionData]
            newArray = [...this.state.optionData]
            newArray[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex]['children'] = []
            let newSelectedFarms = this.state.selectedFarms
            newSelectedFarms = newSelectedFarms.filter(farm => farm.id !== removedItem.id)
            this.setState({
                optionData: newArray,
                selectedFarms: newSelectedFarms
            }, () => {
                console.log("New state: ", this.state)
                // this.checkDisables()
            })
            this.resetGeneratorsValues()
        }
        else if (category === "generator") {
            const elementsIndex = this.state.optionData.findIndex(element => element.id === removedItem.company_id)
            const elementsSitesIndex = this.state.optionData[elementsIndex]['children'].findIndex(element => element.id === removedItem.site_id)
            const elementsFarmsIndex = this.state.optionData[elementsIndex]['children'][elementsSitesIndex]['children'].findIndex(element => element.id === removedItem.farm_id)
            const elementsGeneratorIndex = this.state.optionData[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex]['children'].findIndex(element => element.id === removedItem.id)
            let newArray = [...this.state.optionData]
            newArray = [...this.state.optionData]
            newArray[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex]['children'][elementsGeneratorIndex]['children'] = []
            //--------
            let newSelectedGenerators = this.state.selectedGenerators
            newSelectedGenerators = newSelectedGenerators.filter(gen => gen.id !== removedItem.id)
            this.setState({
                optionData: newArray,
                selectedGenerators: newSelectedGenerators
            }, () => {
                console.log("New state: ", this.state)
                // this.checkDisables()
            });
            this.resetCamerasValues();
        }
        else if (category === "camera") {
            let newSelectedCameras = this.state.selectedCameras
            newSelectedCameras = newSelectedCameras.filter(cam => cam.id !== removedItem.id)
            this.setState({
                selectedCameras: newSelectedCameras
            }, () => {
                console.log("New state: ", this.state)
                // this.checkDisables()
            }); 
        }
    }
    /**
     * Handles the changes in the checkboxes
     * @param {String} checkboxType 
     */
    toggleTypeCheckbox = (checkboxType) => {
        let newTypes = this.state.eventTypes
        newTypes[checkboxType] = !newTypes[checkboxType]
        this.setState({ eventTypes: newTypes })
    }
    /**
     * Handles the changes in the checkboxes
     * @param {String} checkboxType 
     */
    toggleExtraTypeCheckbox = (checkboxType) => {
        let newTypes = this.state.extraEventTypes
        newTypes[checkboxType]['checked'] = !newTypes[checkboxType]['checked']
        this.setState({ extraEventTypes: newTypes })
    }
    /*
     * some more filter methods
     */
    /**
     * Handles the changes in the checkbox for video 
     */
    toggleHasVideoCheckbox = () => {
        const {hasVideo} = this.state;
        this.setState({ hasVideo: !hasVideo });
    }
    /*
     * some more filter methods
     */
    /**
     * Handles the changes in the radio for sorting
     */
    changeSortDirection = (sort) => {
        this.setState({ sortDirection: sort });
    }
    /**
     * Handles the changes in the radio for sorting
     */
    changeSortColumn = (event) => {
        this.setState({ sortColumn: event.target.value });
    }
 
    /**
     * Handles the changes in the camera mode
     */
    changeCameraMode = (event) => {
        this.setState({ cameraMode: event.target.value });
    }
    /**
     * Updates and syncs the selected item in the states onSelectBird event
     * @param {Array} selectedList 
     * @param {Object} selectedItem 
     */
    onSelectBird = (selectedList, selectedItem) => {
        let selected = this.state.selectedBirds;
        selected.push(selectedItem)
        this.setState({
            selectedBirds: selected,
        })  
    }
     /**
     * Updates and syncs the selected item in the states onRemoveBird event
     * @param {Array} selectedList 
     * @param {Object} selectedItem 
     */
     onRemoveBird = (selectedList, removedItem) => {
        let newSelectedBirds = this.state.selectedBirds
            newSelectedBirds = newSelectedBirds.filter(bird => bird.id !== removedItem.id)
            this.setState({
                selectedBirds: newSelectedBirds
            }, () => {
                console.log("New state: ", this.state)
                // this.checkDisables()
            })
    }

    resetMoreFilter = () => {
        this.resetBirdsValues();
        // const {birdsData} = this.state;
        let defaultSortingDirection = this.defaultSortDirection;
        let defaultSortingColumn = this.defaultSortColumn;
        this.setState({
            // selectedBirds: birdsData,
            hasVideo: true,
            sortDirection: defaultSortingDirection,
            sortColumn: defaultSortingColumn,
            cameraMode: this.defaultCameraMode
        });
    }
    /**
     * Renders the events page
     */
    render() {
        Modal.setAppElement("#root")

        const { optionData, selectedCompanies, selectedSites, selectedFarms, tableSpinner, events, showModal,
            selectedGenerators, selectedCameras, date, pagingControl, eventTypes, totalEvents, statistics, showDetails, hasVideo, selectedBirds, birdsData, sortDirection, sortColumn, cameraMode, extraEventTypes} = this.state
        const { sitesDisabled, farmsDisabled, generatorsDisabled, camerasDisabled,  } = this.checkDisables()
        const { activeCount, inactiveCount } = this.getActiveCount()
        let searchDisabled = true
        if (auth.role_id <= 1)
            searchDisabled = selectedCompanies.length <= 0
        else
            searchDisabled = selectedSites.length <= 0

        const sitesData = []
        optionData.map(company => company['children'].map(site => sitesData.push(site)))
        const farmsData = []
        sitesData.map(site => site['children'].map(farm => farmsData.push(farm)))
        const generatorsData = []
        farmsData.map(farm => farm['children'].map(gen => generatorsData.push(gen)))
        const camerasData = []
        generatorsData.map(gen => gen['children'].map(cam => camerasData.push(cam)))
        let alert = '';
        if (this.state.alert)
            alert = <div className={this.state.alertClass} style={{ margin: "0 20%" }} role="alert">{this.state.alertMsg}</div>

        return (
            <div className="container-inner-wrapper">
                <h2 className="my-title"> Events </h2>
                <div>{alert}</div>
                <hr />
                <div>
                    <div className="row">
                        {auth.role_id <= 1 && <div className={"col mb-3"}>
                            <Multiselect
                                options={optionData}
                                onSelect={this.onSelect.bind(this, "company")}
                                onRemove={this.onRemove.bind(this, "company")}
                                displayValue="name"
                                placeholder="Company"
                                closeIcon="cancel"
                                avoidHighlightFirstOption={true}
                                ref={this.multiselectRefCompanies}
                                selectedValues={this.state.selectedCompanies}
                            />
                        </div>}
                        <div className={sitesDisabled ? "col disabled" : "col mb-3"}>
                            <Multiselect
                                options={sitesData}
                                selectedValues={selectedSites.map(site => site)}
                                onSelect={this.onSelect.bind(this, "site")}
                                onRemove={this.onRemove.bind(this, "site")}
                                displayValue={auth.role_id > 1 ? "name" : "view_name"}
                                placeholder="Site"
                                closeIcon="cancel"
                                avoidHighlightFirstOption={true}
                                ref={this.multiselectRefSites}
                            />
                            {sitesDisabled && <p style={warningStyle}> Unavailable: please select a Company </p >}
                            {selectedSites.length > 0 && <button type="button" className="btn btn-link float-right sm" onClick={() => this.resetSitesValues()}>Clear</button>}

                        </div>
                        <div className={farmsDisabled ? "col disabled" : "col mb-3"}>
                            <Multiselect
                                options={farmsData}
                                selectedValues={selectedFarms.map(farm => farm)}
                                onSelect={this.onSelect.bind(this, "farm")}
                                onRemove={this.onRemove.bind(this, "farm")}
                                displayValue="view_name"
                                placeholder="Farm"
                                closeIcon="cancel"
                                avoidHighlightFirstOption={true}
                                ref={this.multiselectRefFarms}
                            />
                            {farmsDisabled && <p style={warningStyle}> Unavailable: please select a Site </p >}
                            {selectedFarms.length > 0 && <button type="button" className="btn btn-link float-right sm" onClick={() => this.resetFarmsValues()}>Clear</button>}

                        </div>
                        <div className={generatorsDisabled ? "col disabled" : "col mb-3"}>
                            <Multiselect
                                options={generatorsData}
                                selectedValues={selectedGenerators.map(gen => gen)}
                                onSelect={this.onSelect.bind(this, "generator")}
                                onRemove={this.onRemove.bind(this, "generator")}
                                displayValue="view_name"
                                placeholder="Generator"
                                closeIcon="cancel"
                                avoidHighlightFirstOption={true}
                                ref={this.multiselectRefGenerators}
                            />
                            {generatorsDisabled && <p style={warningStyle}> Unavailable: please select a Farm </p >}
                            {selectedGenerators.length > 0 && <button type="button" className="btn btn-link float-right sm" onClick={() => this.resetGeneratorsValues()}>Clear</button>}
                        </div>
                        {/** camera filter */}
                        <div className={camerasDisabled ? "col disabled" : "col mb-3"}>
                            <Multiselect
                                options={camerasData}
                                selectedValues={selectedCameras.map(cam => cam)}
                                onSelect={this.onSelect.bind(this, "camera")}
                                onRemove={this.onRemove.bind(this, "camera")}
                                displayValue="view_name"
                                placeholder="Camera"
                                closeIcon="cancel"
                                avoidHighlightFirstOption={true}
                                ref={this.multiselectRefCameras}
                            />
                            {camerasDisabled && <p style={warningStyle}> Unavailable: please select a Generator </p >}
                            {selectedCameras.length > 0 && <button type="button" className="btn btn-link float-right sm" onClick={() => this.resetCamerasValues()}>Clear</button>}
                        </div>
                        <div className='col-12'>
                        <div className='row'  style={{border:"1px solid #ccc", borderRadius:"5px", padding:'10px', marginBottom:"25px"}}>
                         {/** bird filter */}
                         <FormLabel component="legend"><span style={{ color: "#ffffff" }} >More Filters:</span></FormLabel>
                            <div className={"col mb-3"}>
                                <Multiselect
                                    options={birdsData}
                                    selectedValues={selectedBirds.map(bird => bird)}
                                    onSelect={this.onSelectBird.bind(this)}
                                    onRemove={this.onRemoveBird.bind(this)}
                                    displayValue="category"
                                    placeholder="Birds"
                                    closeIcon="cancel"
                                    avoidHighlightFirstOption={true}
                                    ref={this.multiselectRefBirds}
                                />
                                {selectedBirds.length > 0 && <button type="button" className="btn btn-link float-right sm" onClick={() => this.resetBirdsValues()}>Clear</button>}
                            </div>

                            {/** night camera / day camera */}
                        
                        {/** have video or not filter */}

                            <div className={"col mb-3"}>
                                <>
                                    <FormLabel component="legend"><span style={{ color: "#ffffff" }} >Sort By</span>
                                    <Select
                                        className='mx-3'
                                        style={{ color: "#ffffff"}} 
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={sortColumn}
                                        onChange={this.changeSortColumn}
                                        >
                                        <MenuItem value={'timestamp'}>Date</MenuItem>
                                        <MenuItem value={'event_type'}>Action</MenuItem>
                                        <MenuItem value={'annotation'}>Bird</MenuItem>
                                        <MenuItem value={'farm'}>Farm</MenuItem>
                                        </Select>
                                        </FormLabel>
                                    <FormControlLabel
                                        value="asc"
                                        control={<Radio style={{ color: "#ffffff" }} />}
                                        label="Ascending"
                                        labelPlacement="end"
                                        onChange={() => this.changeSortDirection( 'asc' )}
                                        checked={ sortDirection === 'asc' }
                                        style={{ color: "#ffffff" }}
                                    />
                                    <FormControlLabel
                                        value="desc"
                                        control={<Radio style={{ color: "#ffffff" }} />}
                                        label="Descending"
                                        labelPlacement="end"
                                        onChange={() =>  this.changeSortDirection( 'desc' )}
                                        checked={ sortDirection === 'desc'}
                                        style={{ color: "#ffffff" }}
                                    />
                                </>
                                <br />
                                <FormControlLabel
                                    value="video"
                                    control={<Checkbox style={{ color: "#ffffff" }} />}
                                    label="Event has Video?"
                                    labelPlacement="end"
                                    onChange={() => this.toggleHasVideoCheckbox( )}
                                    checked={hasVideo}
                                    style={{ color: "#ffffff" }}
                                />
                                {/* <FormLabel component="legend"><span style={{ color: "#ffffff" }} >Camera Mode</span>
                                    <Select
                                        className='mx-3'
                                        style={{ color: "#ffffff"}} 
                                        labelId="camera-mode-label"
                                        id="camera-mode"
                                        value={cameraMode}
                                        onChange={this.changeCameraMode}
                                        >
                                        <MenuItem value={'all'}>All</MenuItem>
                                        <MenuItem value={'day'}>Day</MenuItem>
                                        <MenuItem value={'night'}>Night</MenuItem>
                                        </Select>
                                        </FormLabel>
                                <br/> */}
                                <div className="align-text-bottom ">
                                    <button className="btn btn-danger" style={buttonStyle} onClick={() => this.resetMoreFilter()} >Reset More Filters</button>
                                </div>
                            </div>
                            
                        </div>
                            
                    </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="row">
                            <div className="col">
                                <FormControl component="fieldset" style={{ marginRight: "2%" }}>
                                    <FormLabel component="legend"><span style={{ color: "#ffffff" }} >Event Type:</span></FormLabel>
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            value="Detection"
                                            control={<Checkbox style={{ color: "#ffffff" }} />}
                                            label="Detection"
                                            labelPlacement="end"
                                            onChange={() => this.toggleTypeCheckbox("detection")}
                                            checked={eventTypes.detection}
                                            style={{ color: "#ffffff" }}
                                        />
                                        <FormControlLabel
                                            value="Deterrence"
                                            control={<Checkbox style={{ color: "#ffffff" }} />}
                                            label="Deterrence"
                                            labelPlacement="end"
                                            onChange={() => this.toggleTypeCheckbox("deterrence")}
                                            checked={eventTypes.deterrence}
                                            style={{ color: "#ffffff" }}
                                        />
                                        <FormControlLabel
                                            value="ShutDown"
                                            control={<Checkbox style={{ color: "#ffffff" }} />}
                                            label="Shut Down"
                                            labelPlacement="end"
                                            onChange={() => this.toggleTypeCheckbox("shutdown")}
                                            checked={eventTypes.shutdown}
                                            style={{ color: "#ffffff" }}
                                        />
                                        {
                                            
                                            Object.keys(extraEventTypes).map( (extraEventType,  extraEventTypeIndex) => 
                                                (
                                                    <FormControlLabel
                                                    key={extraEventTypeIndex}
                                                    value={extraEventTypes[extraEventType]['value']}
                                                    control={<Checkbox style={{ color: "#ffffff" }} />}
                                                    label={extraEventTypes[extraEventType]['label']}
                                                    labelPlacement="end"
                                                    onChange={() => this.toggleExtraTypeCheckbox(extraEventTypes[extraEventType]['key'])}
                                                    checked={extraEventTypes[extraEventType]['checked']}
                                                    style={{ color: "#ffffff" }}
                                                    />
                                                )
                                            )
                                        }
                                        
                                    </FormGroup>
                                </FormControl>
                            </div>

                            <div className="col">
                                <FormLabel component="legend"><span style={{ color: "#ffffff" }} >Calendar:</span></FormLabel>

                                <DateRangePicker
                                    date={date}
                                    onChangeDate={this.onChangeDate}
                                />
                            </div>
                            <div className="col">
                                <div className="row">
                                    <span style={{ margin: "20px" }} />
                                    <div className="align-text-bottom ">
                                        <button className={searchDisabled ? "btn btn-primary disabled" : "btn btn-primary"} style={buttonStyle} onClick={() => this.search()}>Search</button>
                                    </div>
                                    <div className="align-text-bottom ">
                                        <button className="btn btn-danger" style={buttonStyle} onClick={() => this.resetAll()} >Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {pagingControl.currentPage > 0 &&
                    <div className="row" style={{ marginTop: '25px' }}>
                        <div className="col-lg-6">
                            <EventsBar style={{ height: "50px" }}
                                total={totalEvents}
                                detections={statistics.detections}
                                deterrences={statistics.deterrences}
                                shutdowns={statistics.shutdowns}
                                extra_event_types={statistics.extra_event_types ?? {}}
                            />
                        </div>
                        <div className="col-lg-6">
                            <StatusPie
                                active={activeCount}
                                inactive={inactiveCount}
                            />
                        </div>
                        {eventTypes.detection && statistics.detection_birds && <div className="col-12">
                            <DetectionBirdsBar style={{ height: "100px" }}
                                detection_birds={statistics.detection_birds}
                            />
                        </div>}
                    </div>}
                {totalEvents > 0 && <div style={{ marginTop: '50px' }}>
                    <div className="row">
                        <div className="col-lg">
                            <strong style={{ color: "#000" }}>Total: </strong><span style={{color:"#999"}}>{totalEvents}</span>
                        </div>
                        <div className="col-lg">
                            <strong style={{ color: "#87abc9" }}>Detections: </strong><span style={{color:"#999"}}>{statistics.detections}</span>
                        </div>
                        <div className="col-lg">
                            <strong style={{ color: "#ada661" }}>Deterrences: </strong><span style={{color:"#999"}}>{statistics.deterrences}</span>
                        </div>
                        <div className="col-lg">
                            <strong style={{ color: "#ce8d8d" }}>Shut Downs: </strong><span style={{color:"#999"}}>{statistics.shutdowns}</span>
                        </div>
                        {
                        Object.keys(statistics.extra_event_types ?? {}).map((extraEventType, extraEventTypeIndex) => {
                            return <div key={extraEventTypeIndex} className="col-lg">
                            <strong style={{ color: "#ce8d8d" }}>{statistics.extra_event_types[extraEventType]['label']}: </strong><span style={{color:"#999"}}>{statistics.extra_event_types[extraEventType]['count']}</span>
                        </div>
                        }) }

                        <div className="col-lg">
                            <strong style={{ color: "#444" }}>Items per Page : </strong><span style={{color:"#999"}}>{events.length}</span>
                        </div>
                    </div>
                    <EventsTable
                        browserHistory={this.props.history}
                        events={events}
                        tableSpinner={tableSpinner}
                        pagingControl={pagingControl}
                        changePage={this.changePage}
                        setAlert={this.setAlert}
                        mode={this.props.mode}
                    />

                    <div className="float-right">

                        {!showDetails &&
                            <div>
                                <button className="btn btn-primary" style={buttonStyle} onClick={this.showDetails}>
                                    <span style={buttonLink}>Show Charts</span>
                                </button>
                                <button className="btn btn-primary " style={buttonStyle} onClick={this.downloadReport} >
                                    <span className="d-none d-lg-inline">Download Excel</span>
                                    <FontAwesomeIcon icon={faFileExcel} style={{ marginLeft: "5px" }} />
                                </button>
                            </div>
                        }
                    </div>


                    {showDetails &&
                        <Reports
                            companies={selectedCompanies}
                            sites={selectedSites}
                            farms={selectedFarms}
                            generators={selectedGenerators}
                            date={date}
                            downloadReport={this.downloadReport}
                            eventTypes={eventTypes}
                        />
                    }
                </div>}
                <Modal
                    isOpen={showModal}
                    onRequestClose={this.handleCloseModal}
                    style={modalStyle}
                >
                    <div className="card">
                        <div className="card-body" style={modalCard}>
                            <h5 className="card-title text-center">Exporting to Excel</h5>

                            <div className="text-center" >
                                <br />
                                <LittleSpinner />
                            </div>
                        </div>
                    </div>
                    {/* <div className="container" style={sureStyle}>Are you sure you want to delete <strong>{this.props.user.username}</strong>?</div>
                    <button onClick={this.handleCloseModal} className="float-left">Cancel</button>
                    <button onClick={this.handleYes} className="float-right">Yes, I am sure</button> */}
                </Modal>
            </div>
        )
    }
}

const buttonStyle = { margin: "10px 10px" }

const buttonLink = { color: "white" }

const warningStyle = { color: "#7a7979" }

const spanStyle = { color: "#555" }

const modalStyle = {
    content: {
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        borderStyle: "none",
        padding: "0 0"
    },
    overlay: { zIndex: 10000 },
}

const modalCard = {
    padding: "25px 90px",
    borderStyle: "none",
    border: "1px solid #dde7f1"
}

export default withRouter(Events)