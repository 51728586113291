import React, { Component } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import auth from "../../helpers/auth";

export class ImagePreviewV1 extends Component {
    /**
     * Displays the image part of the event details
     * page
     */
    canvasRef = React.createRef();

    state = {
        canvas: {
            canvasWidth: 1000,
            canvasHeight: 1000
        },
        viewWidth: 0.8,
        imageLoaded: false,
        showBBox: true,
        showLocation: false,
        spinnerPos: "750px"
    }

    /**
     * Initializes the canvas
     */
    componentDidMount() {
        const { imageUrl, location } = this.props
        const cWidth = parseInt(window.innerWidth * this.state.viewWidth)
        const cHeight = parseInt(cWidth / 1.9)

        this.setState({
            canvas: {
                canvasWidth: cWidth,
                canvasHeight: cHeight
            },
            imageUrl: imageUrl,
            location: location,
        })
        this.initImage(imageUrl)
    }

    /**
     * Fetches the image from the S3 url
     * @param {string} iurl
     */
    initImage = (iurl) => {
        let image = new Image();
        image.src = iurl
        this.setState({
            image: image,
            imageLoaded: false
        })
        image.onload = () => {
            this.setState({
                imageLoaded: true
            })
            this.drawImage()
        }
    }
    /**
     * draws the image proeperly on the canvas
     */
    drawImage = () => {
        const { canvasWidth, canvasHeight } = this.state.canvas
        const { image, showBBox } = this.state


        const ctx = this.canvasRef.current.getContext('2d')
        const projectiveScale = image.height / canvasHeight;

        // console.log("Drawing image")  
        // console.log("Canvas shape: ", canvasWidth, canvasHeight)
        // console.log("Image shape:" , image.width, image.height)
        // const projectiveScale = image.width/canvasWidth;
        // console.log("projectiveScale: ", projectiveScale)
        this.setState({
            projectiveScale: projectiveScale
        })

        const newWidth = parseInt(image.width / projectiveScale)
        const newHeight = parseInt(image.height / projectiveScale)
        this.setState({
            canvas: {
                canvasWidth: newWidth,
                canvasHeight: newHeight
            },

        })
        // console.log("New Image shape:" , newWidth, newHeight)
        ctx.drawImage(image, 0, 0, newWidth, newHeight);
        if (showBBox)
            this.drawEventLocation()
    }
    /**
     * draws the bounding box on the canvas
     */
    drawEventLocation = () => {
        // console.log("Drawing location")
        const { canvasWidth, canvasHeight } = this.state.canvas
        const { projectiveScale } = this.state
        const bbox = this.state.location
        let x = parseInt(bbox.xmin / projectiveScale) > 0 ? parseInt(bbox.xmin / projectiveScale) : 0
        let y = parseInt(bbox.ymin / projectiveScale) > 0 ? parseInt(bbox.ymin / projectiveScale) : 0
        let width = parseInt(bbox.xmax / projectiveScale) - parseInt(bbox.xmin / projectiveScale)
        let height = parseInt(bbox.ymax / projectiveScale) - parseInt(bbox.ymin / projectiveScale)
        if (x === y) {
            x -= 20
            y -= 20
            width += 20
            height += 20
        }
        width = width > canvasWidth ? canvasWidth : width
        height = height > canvasHeight ? canvasHeight : height

        const ctx = this.canvasRef.current.getContext('2d')
        ctx.beginPath()
        ctx.lineWidth = "1"
        ctx.strokeStyle = "red"
        ctx.rect(x, y, width, height)
        ctx.stroke()
    }
    /**
     * handles the checkbox to either draw the bbox or not.
     */
    checkBoxClicked = () => {
        // console.log("chebox clicked")
        const { showBBox } = this.state
        this.setState({
            showBBox: !showBBox
        }, () => this.drawImage())
    }
    /**
     * opens the image in a new tab
     */
    onNewTab = () => {
        const { imageUrl } = this.state
        let win = window.open(imageUrl, '_blank');
        win.focus();
    }

    /**
     * toggle location button status
     */
    handleShowLocation() {
        const { showLocation } = this.state;
        this.setState({ showLocation: !showLocation });
    }

    /**
     * displays the image part of the page
     */
    render() {
        const { spinner, imageLoaded, showBBox, showLocation } = this.state
        const { canvasWidth, canvasHeight } = this.state.canvas

        let location = "xx"
        if (this.state.location !== undefined) {
            location = this.state.location;
        }
        return (

            <div style={{ "marginTop": "10px", "width": "100%" }}>
                <h2 className="my-title2">Multimedia</h2>
                <div style={{ position: "relative", backgroundColor: "#f4f4f6" }}>
                    <PinchZoomPan maxScale={10} style={canvasStyle} doubleTapBehavior="zoom" position="center">
                        <canvas ref={this.canvasRef} width={canvasWidth} height={canvasHeight}></canvas>
                    </PinchZoomPan>
                    {/* {!imageLoaded && 
                        <div className="spinner-border  my-spinner" role="status" style={canvasLoadingSpinner}>
                            <span className="sr-only">Loading...</span>
                        </div> 
                     } */}
                </div>
                {/*<div style={{display: "inline"}}>*/}
                {/*    <p style={{float: "right"}}> To open image in a new Tab click <button className="btn btn-link"*/}
                {/*                                                                          onClick={() => this.onNewTab()}>here</button>*/}
                {/*    </p>*/}
                {/*    <Checkbox color="primary" checked={showBBox} onChange={() => this.checkBoxClicked()}/>Bounding Box*/}
                {/*    <div style={{clear: "both"}}></div>*/}
                {/*</div>*/}
                <div className="row my-2">
                    {this.props.children}
                    <div className="col-8 text-right">
                        <button className={`primaryBtn mx-3 ${showBBox ? 'active' : ''}`} onClick={() => this.checkBoxClicked()}>{'Bounding Box'}</button>
                        <button className="primaryBtn ">{'Trajectory'}</button>
                        {auth.role_id < 2 && <button className="primaryBtn ml-3 " onClick={() => { this.handleShowLocation() }}>{'Location'}</button>}

                        <button className="primaryBtn mx-3">{'Reset View'}</button>
                        <button onClick={() => this.onNewTab()} className="primaryBtn ml-3">{'Open Image in New Tab'}</button>
                    </div>
                </div>
                {showLocation &&
                    <div className='row'>
                        <div className="col text-center">
                            <p style={{ color: "#fff" }}> Location <br />
                                [xmin, xmax | ymin, ymax] <br />
                                {location.xmin}, {location.xmax} | {location.ymin}, {location.ymax}
                            </p>
                        </div>
                    </div>
                }
            </div>
        )
    }


}


const canvasStyle = {
    // position: "absolute",
    // left: "0%",
    // right: "0%",
    border: "solid 2px #777",
    borderRadius: "5px",
    backgroundColor: "#0001",
    margin: "15px auto",
}

const canvasLoadingSpinner = {
    position: "absolute",
    left: "0%",
    right: "0%",
    margin: "20% auto",
    zIndex: 1000
}


export default ImagePreviewV1
