import React, { Component } from 'react'
import Events from './Events'
import Modal from 'react-modal'
class NightEvents extends Component {

    
    /**
     * Renders the events page
     */
    render() {
        Modal.setAppElement("#root")
        return (
            <div className="day-event-container">
                <Events mode={'night'} />
            </div>
        )
    }
}


export default NightEvents