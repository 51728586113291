import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faMinusCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import axios from 'axios'
import auth from '../../helpers/auth'
import { IconButton, Tooltip } from '@material-ui/core';

//expand with model and nodeApp versions and infos!
export class EventAnnotationV1 extends Component {
    state = {
        opened: false,
        okStatus: 0,
        submitStatus: 0,
        newCategoryInput: "",
    }
    /**
     * Initialize the component
     */
    componentDidMount() {
        const { site, object } = this.props
        const isAnnotated = object.annotation != null
        const sitesAnnotatios = site.annotations

        // console.log("site ==> ", site)
        // console.log("object ==> ", object)
        // console.log("isAnnotated ==> ", isAnnotated)
        // console.log("Annot Categ ==> ", object.annotation)
        // console.log("sitesAnnotatios ==> ", sitesAnnotatios)

        this.setState({
            site: site,
            object: object,
            isAnnotated: isAnnotated,
            selectedCategoryID: isAnnotated ? object.annotation.id : "",
            sitesAnnotatios: sitesAnnotatios,
        })
    }
    /**
     * Expands the component
     * @param {object} action 
     */
    handleExpand = (action) => {
        this.setState({
            opened: action === "maximize" ? true : false
        })
    }
    /**
     * Synchronises the selected category with the state
     * @param {object} event 
     */
    handleChangeCategory = (event) => {
        const selcedAnnotationId = event.target.value

        this.setState({
            selectedCategoryID: selcedAnnotationId
        });
    }
    /**
     * Synchronises the new given category in the input with the state
     * @param {object} event 
     */
    handleNewCategoryInput = input => e => {
        this.setState({ [input]: e.target.value })
    }
    /**
     * posts and store the new created annotation in the backend
     */
    postAnnotation = () => {
        const { newCategoryInput, site } = this.state
        this.setState({ okStatus: 1 })
        const data = { category: newCategoryInput }
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        axios.post(process.env.REACT_APP_API_URL + 'sites/' + site.id + '/annotations', data, config)
            .then(response => {
                let newAnnot = response.data
                let newSitesAnnotatios = this.state.sitesAnnotatios
                newSitesAnnotatios.push(newAnnot)
                this.setState({
                    sitesAnnotatios: newSitesAnnotatios,
                    okStatus: 2,
                    newCategoryInput: ""
                }, () => setTimeout(() => this.resetOKStatus(), 3000))

            })
            .catch(error => {
                console.log(error)
                this.setState({
                    okStatus: 3
                }, () => setTimeout(() => this.resetOKStatus(), 3000))
            });
    }
    /**
     * submits the annotation of the object in the database
     */
    submitAnnotation = () => {
        const { selectedCategoryID, object } = this.state
        this.setState({ submitStatus: 1 })
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        console.log(selectedCategoryID)
        axios.post(process.env.REACT_APP_API_URL + 'annotations/' + selectedCategoryID + '/objects/' + object.id + '/connect', {}, config)
            .then(response => {
                let newObject = response.data
                this.setState({
                    object: newObject,
                    submitStatus: 2,
                    isAnnotated: true
                }, () => setTimeout(() => this.resetOKStatus(true), 3000))

            })
            .catch(error => {
                console.log(error)
                this.setState({
                    submitStatus: 3
                }, () => setTimeout(() => this.resetOKStatus(), 3000))
            });
    }
    /**
     * resets the spinners and if it is reqiured minimizes the view
     * @param {bool} minimize 
     */
    resetOKStatus = (minimize = false) => {
        this.setState({
            okStatus: 0,
            submitStatus: 0
        })
        if (minimize)
            this.handleExpand("minimize")
    }




    render() {
        const { opened, sitesAnnotatios, okStatus, submitStatus, newCategoryInput, selectedCategoryID, isAnnotated } = this.state

        return (
            <div style={annotationDivStyle} className="annotationDiv row">
                <div className='col-md-7'>
                    <FormControl style={{ margin: "5px auto", width: "100%" }}>
                        <Select
                            labelId="bird-category-options-label"
                            id="bird-category"
                            value={selectedCategoryID ?? ''}
                            onChange={this.handleChangeCategory}
                            style={{ color: "#fff" }}
                        >
                            <MenuItem value="">
                                <em>None {selectedCategoryID}</em>
                            </MenuItem>
                            {sitesAnnotatios && sitesAnnotatios.map(annotation => <MenuItem key={`annotation-bird-categories-${annotation.id}`} value={annotation.id}>{annotation.category}</MenuItem>)}
                        </Select>
                        <FormHelperText style={{ color: 'grey' }}>Site's bird categories</FormHelperText>
                    </FormControl>
                </div>
                <div className='col-md-5'>
                    <button className="btn btn-success" style={{ float: "left" }} onClick={() => this.submitAnnotation()}>
                        OK
                        {submitStatus === 1 && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>}
                        {submitStatus === 2 && <span><FontAwesomeIcon icon={faCheck} size={'xs'} style={{ color: "#fff", marginLeft: "5px" }} /></span>}
                        {submitStatus === 3 && <span><FontAwesomeIcon icon={faTimes} size={'xs'} style={{ color: "#f00", marginLeft: "5px" }} /></span>}
                    </button>
                    {auth.role_id < 2 && <Tooltip arrow title="Add new Bird category"><button className="btn btn-primary float-right" onClick={() => this.handleExpand('maximize')} >
                        <FontAwesomeIcon icon={faPlus} size='1x' /></button></Tooltip>}

                </div>
                <div style={{ clear: "both" }}></div>


                {opened && <>
                    <hr className="my-Hr" />
                    <p>Add a new Category (for the current Site).</p>
                    <div className=" row">
                        <div className="col-sm-7">
                            <input
                                type="text"
                                className="form-control"
                                ref="category"
                                name="category"
                                required autoFocus
                                style={{ backgroundColor: "#dafada" }}
                                value={newCategoryInput}
                                onChange={this.handleNewCategoryInput("newCategoryInput")}
                            />
                        </div>
                        <div className="col-sm-5">
                            <button className="btn btn-success annotation-ok-btn col-form-label float-left" onClick={() => this.postAnnotation()}>
                                OK
                                {okStatus === 1 && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>}
                                {okStatus === 2 && <span><FontAwesomeIcon icon={faCheck} size={'xs'} style={{ color: "#fff", marginLeft: "1px" }} /></span>}
                                {okStatus === 3 && <span><FontAwesomeIcon icon={faTimes} size={'xs'} style={{ color: "#f00", marginLeft: "1px" }} /></span>}

                            </button>
                            <button className="btn btn-danger annotation-ok-btn col-form-label float-right" onClick={() => this.handleExpand('minimize')}>
                                <FontAwesomeIcon icon={faTimes} size='1x' /></button>

                        </div>
                    </div>
                </>}
                {/* <div className="annotation-minimize-button-container">
                    <button className="btn btn-link btn-sm" style={{ float: "right" }} onClick={() => this.handleExpand("minimize")}>
                        Minimize
                    </button>
                    <div style={{ clear: "both" }}></div>
                </div> */}
            </div>

        )
    }
}

const annotationDivStyle = {
    // position: "fixed",
    // right: "10px",
    // bottom: "10px",
    // width: "400px",
    // border: "solid 2px #6aa76a",
    // padding: "1%",
    // borderRadius: "10px",
    // backgroundColor: "#dafada",
    // zIndex: 1000
}

const minimizedStyle = {
    // position: "fixed",
    // right: "10px",
    // bottom: "10px",
    // border: "solid 2px #6aa76a",
    // borderRadius: "50%",
    // backgroundColor: "#dafada",
    // padding: "1%",
    // zIndex: 5000
}

const annotatedStyle = {
    // backgroundColor: "#3a973a",
    // color: "#fff",
    // borderRadius: "50%",
    // border: "solid 1x #1a771a",
    // padding: "3px",

}

export default EventAnnotationV1
