import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class GeneratorOverview extends Component {
    render() {
        const generator = this.props.generator
        const dayCameras = generator.cameras.filter(cam => cam.type === 'RGB');
        const nightCameras = generator.cameras.filter(cam => cam.type !== 'RGB');
        const tdWidthPercentage = 80 / (dayCameras.length + nightCameras.length + (dayCameras.length > 0 ? 1:0) + (nightCameras.length > 0 ? 1:0));
        const tdWidthStyle = {
            width: `${tdWidthPercentage}%`
        };
        
        const dayNightCameraTableSplitterClass = nightCameras.length > 0 ? 'dayNightCameraTableSplitter': '';
        return (
            <div className="new-design">
            <table   className="generatorsTopTable noStyledTable" >
                <tbody>
                <tr>
                    <td style={firstTdWidthStyle}>
                    <span className="generatorImage" ><img src="/generator_icon.png"/></span>
                    <span className="generatorName">{generator.name}</span></td>
                    {dayCameras.length > 0 && <td style={{padding:"0", width: `${heardersTableTdWidth}%`}}>
                         <table  className="noStyledTable headersTable"  style={{width: `100%`}}>
                            <tbody>
                                <tr >
                                    <td colSpan={dayCameras.length + 1} className="dayCameraHeading">DAY CAMERAS</td>
                                </tr>
                                <tr className="heading-row daycamera">
                                    {dayCameras.map(cam => <td key={`daycamera-${cam.id}`} style={tdWidthStyle}><Link to={"/cameras/" + cam.id}>{cam.name}</Link></td>)}
                                    <td style={tdWidthStyle}>TOTAL<span className="dayCameraDownTriangle"></span></td>
                                </tr>
                            </tbody>
                        </table>
                    </td> }
                    {nightCameras.length > 0 && <td style={{padding:"0", width: `${heardersTableTdWidth}%`}}>
                        <table className="noStyledTable headersTable"  style={{width: `100%`}}>
                            <tbody>
                                <tr>
                                    <td colSpan={nightCameras.length + 1} className="nightCameraHeading">NIGHT CAMERAS</td>
                                </tr>
                                <tr className="heading-row nightcamera">
                                    {nightCameras.map(cam => <td key={`nightcamera-${cam.id}`} style={tdWidthStyle}><Link to={"/cameras/" + cam.id}>{cam.name}</Link></td>)}
                                    <td style={tdWidthStyle}>TOTAL<span className="nightCameraDownTriangle"></span></td>
                                </tr>
                            </tbody>
                        </table>
                    </td> }
                </tr>
                <tr className="detections">
                    <td style={firstTdWidthStyle} className="text-left">Detections</td>
                    {dayCameras.length > 0 && <td style={innerTableTdStyle} className={`${dayNightCameraTableSplitterClass}`}>
                        <table className="generatorEventsInnerTable">
                            <tbody>
                                <tr>
                                    {dayCameras.map(cam => <td key={`daycamera-detections-${cam.id}`} style={tdWidthStyle}>{cam.detections}</td>)}
                                    <td style={tdWidthStyle} >
                                        {dayCameras.reduce((accumulator, cam) => cam.detections + accumulator, 0)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td> }
                    {nightCameras.length > 0 && <td style={innerTableTdStyle}>
                        <table className="generatorEventsInnerTable">
                            <tbody>
                                <tr>
                                    {nightCameras.map(cam => <td key={`nightcamera-detections-${cam.id}`} style={tdWidthStyle}>{cam.detections}</td>)}
                                    <td style={tdWidthStyle} >
                                        {nightCameras.reduce((accumulator, cam) => cam.detections + accumulator, 0)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    }

                    </tr>
                    <tr className="deterrences">
                        <td className="text-left">Deterrences</td>
                        {dayCameras.length > 0 && <td style={innerTableTdStyle} className={`${dayNightCameraTableSplitterClass}`}>
                            <table className="generatorEventsInnerTable">
                                <tbody>
                                    <tr>
                                        {dayCameras.map(cam => <td key={`daycamera-deterrences-${cam.id}`} style={tdWidthStyle}>{cam.deterrences}</td>)}
                                        <td style={tdWidthStyle} >
                                            {dayCameras.reduce((accumulator, cam) => cam.deterrences + accumulator, 0)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td> }

                        {nightCameras.length > 0 && <td style={innerTableTdStyle}>
                        <table className="generatorEventsInnerTable">
                            <tbody>
                                <tr>
                                    {nightCameras.map(cam => <td key={`nightcamera-deterrences-${cam.id}`} style={tdWidthStyle}>{cam.deterrences}</td>)}
                                    <td style={tdWidthStyle} >
                                        {nightCameras.reduce((accumulator, cam) => cam.deterrences + accumulator, 0)}
                                    </td>
                                </tr>
                            </tbody>
                            </table>
                        </td> 
                        }
                    </tr>
                    <tr className="shutdowns">
                        <td className="text-left">Shutdowns</td>
                        {dayCameras.length > 0 && <td style={innerTableTdStyle} className={`${dayNightCameraTableSplitterClass}`}>
                            <table className="generatorEventsInnerTable">
                                <tbody>
                                    <tr>
                                        {dayCameras.map(cam => <td key={`daycamera-shutdowns-${cam.id}`} style={tdWidthStyle}>{cam.shutdowns}</td>)}
                                        <td style={tdWidthStyle} >
                                            {dayCameras.reduce((accumulator, cam) => cam.shutdowns + accumulator, 0)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td> }

                        {nightCameras.length > 0 && <td style={innerTableTdStyle}>
                        <table className="generatorEventsInnerTable">
                            <tbody>
                                <tr>
                                    {nightCameras.map(cam => <td key={`nightcamera-shutdowns-${cam.id}`} style={tdWidthStyle}>{cam.shutdowns}</td>)}
                                    <td style={tdWidthStyle} >
                                        {nightCameras.reduce((accumulator, cam) => cam.shutdowns + accumulator, 0)}
                                    </td>
                                </tr>
                            </tbody>
                            </table>
                        </td> }
                    </tr>
                    <tr className="shutdowns_ghost">
                        <td className="text-left">Shutdowns ghost</td>
                        {dayCameras.length > 0 && <td style={innerTableTdStyle} className={`${dayNightCameraTableSplitterClass}`}>
                            <table className="generatorEventsInnerTable">
                                <tbody>
                                    <tr>
                                        {dayCameras.map(cam => <td key={`daycamera-shutdowns_ghost-${cam.id}`} style={tdWidthStyle}>{cam.shutdowns_ghost ?? 0}</td>)}
                                        <td style={tdWidthStyle} >
                                            {dayCameras.reduce((accumulator, cam) => (cam.shutdowns_ghost ?? 0) + accumulator, 0)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td> }

                        {nightCameras.length > 0 && <td style={innerTableTdStyle}>
                        <table className="generatorEventsInnerTable">
                            <tbody>
                                <tr>
                                    {nightCameras.map(cam => <td key={`nightcamera-shutdowns_ghost-${cam.id}`} style={tdWidthStyle}>{cam.shutdowns_ghost ?? 0}</td>)}
                                    <td style={tdWidthStyle} >
                                        {nightCameras.reduce((accumulator, cam) => (cam.shutdowns_ghost ?? 0) + accumulator, 0)}
                                    </td>
                                </tr>
                            </tbody>
                            </table>
                        </td> }
                    </tr>
                   
                </tbody>
                </table>

            </div>
        );
    }
}
const innerTableTdStyle = {
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
}
const firstTdWidthStyle = {
    width: '20%',
    textAlign: 'center'
}
const heardersTableTdWidth = {
    width: '40%',
    textAlign: 'center'
}
export default GeneratorOverview;