import React from 'react'
  
const ProgressBar = ({ bgcolor, progress, height, bgImage }) => {
  let paddingLeftProperty = 10, paddingRightProperty = 10, fontSizeProperty = 12, fontWeightProperty = 700, progressBarWidth=progress;
  // if (progress <=20) { 
  //   paddingLeftProperty = 0
  //   paddingRightProperty = 5
  //   fontSizeProperty = 10
  //   fontWeightProperty = 500
  // }
  // if (progress <= 10) { 
  //   progressBarWidth = 15
  // }
  // if (progress == 0) { 
  //   bgcolor = 'whitesmoke'
  // }
  const borderContainerStyle = {
    border:"1px solid #709C99",
    padding:'5px',
    borderRadius: "20px",
    height: '36px'
  }
    const Parentdiv = {
        height: height,
        width: '100%',
        backgroundColor: 'whitesmoke',
        borderRadius: 40,
      }
      
      const Childdiv = {
        height: '100%',
        width: `${progressBarWidth}%`,
        backgroundColor: bgcolor,
        backgroundImage: bgImage ?? 'none',
        borderRadius:40,
        textAlign: 'right',
      }
      
      const progresstext = {
        paddingLeft: paddingLeftProperty,
        paddingRight: paddingRightProperty,
        color: 'black',
        fontWeight: fontWeightProperty,
        fontSize: fontSizeProperty,
        position: "relative",
        top:-20,
        left: 0,
        display: 'block',
        textAlign:'center'
      }
        
  return (
    <div style={borderContainerStyle}>
    <div style={Parentdiv}>
      <div style={Childdiv}>&nbsp;
      </div>
      </div>
      <span style={progresstext}>{`${progress}%`}</span>
      </div>
    )
}
  
export default ProgressBar;