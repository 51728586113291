import React, { Component } from 'react'
import { Multiselect } from 'multiselect-react-dropdown';
import auth from './helpers/auth'
import axios from 'axios';
import qs from 'qs';
import FormLabel from '@material-ui/core/FormLabel';
import { DateRangePicker } from './DateRangePicker'
class Filters extends Component {
  /**
   * Renders the page that searches and shows the events.
   */

  // intialitation of the Refs of the multiselect inputs.
  multiselectRefCompanies = React.createRef();
  multiselectRefSites = React.createRef();
  multiselectRefFarms = React.createRef();
  multiselectRefGenerators = React.createRef();
  multiselectRefCameras = React.createRef();
  state = {
    alert: false,
    sitesDisabled: true,
    farmsDisabled: true,
    generatorsDisabled: true,
    camerasDisabled: true,
    optionData: [],
    selectedCompanies: [],
    selectedSites: [],
    selectedFarms: [],
    selectedGenerators: [],
    selectedCameras:[],
    showGenerators: this.props.showGenerators,
    showCameras: this.props.showCameras,
    data: this.props.data,
    resetFilter: this.props.resetFilter,
    date: [new Date(), new Date()], 
  }

  /**
   * Removes all the selected items of the companis' multiselect
   */
  resetCompaniesValues() {
    this.multiselectRefCompanies.current.resetSelectedValues();
    this.resetSitesValues()
    let newArray = this.state.optionData
    newArray.map(comp => comp['children'] = [])
    this.setState({
      // sitesDisabled: auth.role_id > 0,
      farmsDisabled: true,
      generatorsData: true,
      selectedCompanies: [],
      optionData: newArray
    })
  }
  /**
   * Removes all the selected items of the sites' multiselect
   */
  resetSitesValues() {
    this.multiselectRefSites.current.resetSelectedValues();
    this.resetFarmsValues()
    this.resetGeneratorsValues()
    let newArray = this.state.optionData
    newArray.map(comp => comp['children'].map(site => site['children'] = []))
    this.setState({
      farmsDisabled: true,
      selectedSites: [],
      optionData: newArray
    })
  }
  /**
   * Removes all the selected items of the farms' multiselect
   */
  resetFarmsValues() {
    this.multiselectRefFarms.current.resetSelectedValues();
    this.resetGeneratorsValues()
    let newArray = this.state.optionData
    newArray.map(comp => comp['children'].map(site => site['children'].map(farm => farm['children'] = [])))
    this.setState({
      generatorsDisabled: true,
      selectedFarms: [],
      optionData: newArray
    })
  }

  /**
   * Removes all the selected items of the generators' multiselect
   */
resetGeneratorsValues() {
  if(this.showGenerators ){
    this.multiselectRefGenerators.current.resetSelectedValues();
  }
  
  this.resetCamerasValues();
 
  let newArray = this.state.optionData
  newArray.map(comp => comp['children'].map(site => site['children'].map(farm => farm['children'].map( gen => gen['children'] = []))))
  this.setState({
      camerasDisabled: true,
      selectedGenerators: [],
      optionData: newArray
  })
}
/**
* Removes all the selected items of the cameras' multiselect
*/
resetCamerasValues() {
  if( this.state.showCameras ){
    this.multiselectRefCameras.current.resetSelectedValues();
  }
  this.setState({
      selectedCameras: []
  })
}

  /**
   * Removes all the selected items of the all multiselects
   */
  resetAll() {
    if (auth.role_id <= 1)
      this.resetCompaniesValues()
    this.resetSitesValues()
    this.resetFarmsValues()
    this.resetGeneratorsValues()
    if(this.props.onResetFilter){
      this.props.onResetFilter();
    }
    
  }
  componentDidMount() {
    // smoothToTop() //Commented for "show more details" rerendering
    this.fetchInitialData();
    if(this.props.resetFilter){
      this.resetAll();
    }
  }
  /**
   * Fetches the inital data. If the user is admin, the companies are fetched
   * otherwise the sites.
   */
  fetchInitialData() {
    const config = {
      headers: { Authorization: `Bearer ${auth.token}` }
    };
    // let route = auth.role_id > 1 ? "sites" : "companies-lite"
    let route = "companies-lite"
    axios.get(process.env.REACT_APP_API_URL + route, config)
      .then(response => {
        let items = [];
        response.data.map((item) => {
          item['children'] = [];
          items.push(item)
        })
        console.log("Initial data: ", items)
        this.setState({
          optionData: items
        }, () => {
          // show the data based on the previously selected fields
          this.showDataUsingLocalStorageDataOnpageLoad();
        });

        if (auth.role_id > 1) {
          //fetch also the sites
          let newSelectedCompanies = []
          items.map(comp => {
            this.fetchData('company', comp)
            newSelectedCompanies.push(comp)
          })
          this.setState({
            selectedCompanies: newSelectedCompanies
          }, () => {
            // show the data based on the previously selected fields
            this.showDataUsingLocalStorageDataOnpageLoad();
        })
        }
      })
      .catch(error => {
        console.log("Error on fetching initial data")
        console.log(error)
      });
  }
  /**
   * When the user selects an item from a category, then in the next category, the children of the item should be
   * fetched in order to be displayed as a optional values. This is what is happening in this page.
   * @param {String} category The category of the item (companies, sites, farms)
   * @param {Object} item The item
   */
  fetchData = (category, item) => {
    /*  
        ROUTES 
        /companies/{cid}/sites
        /sites/{sid}/farms
        /farms/{fid}/generators
    */
    const config = {
      headers: { Authorization: `Bearer ${auth.token}` }
    };
    let route;
    if (category === "company") {
      route = "companies/" + item.id + "/sites"
    }
    else if (category === "site") {
      route = "sites/" + item.id + "/farms"
    }
    else if (category === "farm") {
      route = "farms/" + item.id + "/generators"
      if (this.state.showGenerators === false ){ return }// dont execute if the showGenerators is false
    } else if (category === "generator") {
      route = "generators/" + item.id + "/cameras"
      if (this.state.showCameras === false ){ return }// dont execute if the showCameras is false
    }
    // axios.get(process.env.REACT_APP_API_URL + route, config)
    //   .then(response => {
    //     if (response.data.length === 0) {
    //       const childname = category === "company" ? "sites" : category === "site" ? "farms" : category === "farm" ? "generators" : "'?'"
    //       this.setAlert("warning", "No " + childname + " are found for the " + category + ": " + item.name)
    //       return
    //     }
    //     response.data.map((incomingItem) => {
    //       incomingItem['view_name'] = item['name'] + ": " + incomingItem['name'] // <p><span style={spanStyle}>{item['name'] +":"}</span> {incomingItem['name']}</p>
    //       incomingItem['children'] = []
    //       item['children'].push(incomingItem)
    //     })

    //     let newArray;
    //     if (category === "company") {
    //       //we got sites
    //       const elementsIndex = this.state.optionData.findIndex(element => element.id === item.id)
    //       newArray = [...this.state.optionData]
    //       newArray[elementsIndex]['children'] = item['children']//newArray[elementsIndex] = {...newArray[elementsIndex], children: item['children']}
    //     }
    //     else if (category === "site") {
    //       //we got farms
    //       const elementsIndex = this.state.optionData.findIndex(element => element.id === item.company_id)
    //       const elementsSitesIndex = this.state.optionData[elementsIndex]['children'].findIndex(element => element.id === item.id)
    //       newArray = [...this.state.optionData]
    //       newArray[elementsIndex]['children'][elementsSitesIndex]['children'] = item['children']  //{...newArray[elementsIndex]['children'][elementsSitesIndex], children: item['children']} 
    //     }
    //     else if (category === "farm") {
    //       //we got generators
    //       const elementsIndex = this.state.optionData.findIndex(element => element.id === item.company_id)
    //       const elementsSitesIndex = this.state.optionData[elementsIndex]['children'].findIndex(element => element.id === item.site_id)
    //       const elementsFarmsIndex = this.state.optionData[elementsIndex]['children'][elementsSitesIndex]['children'].findIndex(element => element.id === item.id)
    //       newArray = [...this.state.optionData]
    //       newArray[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex]['children'] = item['children'] //{...newArray[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex], children: item['children']} 
    //     }
    //     this.setState({
    //       optionData: newArray,
    //     }, () => {
    //       console.log("New state: ", this.state)
    //       this.checkDisables()
    //     })
    //   })
    //   .catch(error => {
    //     console.log("Error on fetching data")
    //     console.log(error)
    //   });
    axios.get(process.env.REACT_APP_API_URL + route, config)
            .then(response => {
                if (response.data.length === 0) {
                    const childname = category === "company" ? "sites" : category === "site" ? "farms" : category === "farm" ? "generators" : category === "generator" ? "cameras" : "'?'"
                    this.setAlert("warning", "No " + childname + " are found for the " + category + ": " + item.name)
                    return
                }
                response.data.map((incomingItem) => {
                    incomingItem['view_name'] = item['name'] + ": " + incomingItem['name'] // <p><span style={spanStyle}>{item['name'] +":"}</span> {incomingItem['name']}</p>
                    incomingItem['children'] = []
                    item['children'].push(incomingItem)
                })
                let newArray;
                if (category === "company") {
                    //we got sites
                    const elementsIndex = this.state.optionData.findIndex(element => element.id === item.id)
                    newArray = [...this.state.optionData]
                    newArray[elementsIndex]['children'] = item['children']//newArray[elementsIndex] = {...newArray[elementsIndex], children: item['children']}
                }
                else if (category === "site") {
                    //we got farms
                    const elementsIndex = this.state.optionData.findIndex(element => element.id === item.company_id)
                    const elementsSitesIndex = this.state.optionData[elementsIndex]['children'].findIndex(element => element.id === item.id)
                    newArray = [...this.state.optionData]
                    newArray[elementsIndex]['children'][elementsSitesIndex]['children'] = item['children']  //{...newArray[elementsIndex]['children'][elementsSitesIndex], children: item['children']} 
                }
                else if (category === "farm") {
                    //we got generators
                    const elementsIndex = this.state.optionData.findIndex(element => element.id === item.company_id)
                    const elementsSitesIndex = this.state.optionData[elementsIndex]['children'].findIndex(element => element.id === item.site_id)
                    const elementsFarmsIndex = this.state.optionData[elementsIndex]['children'][elementsSitesIndex]['children'].findIndex(element => element.id === item.id)
                    newArray = [...this.state.optionData]
                    newArray[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex]['children'] = item['children'] //{...newArray[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex], children: item['children']} 
                }
                else if (category === "generator") {
                    //we got generators
                    const elementsIndex = this.state.optionData.findIndex(element => element.id === item.company_id)
                    const elementsSitesIndex = this.state.optionData[elementsIndex]['children'].findIndex(element => element.id === item.site_id)
                    const elementsFarmsIndex = this.state.optionData[elementsIndex]['children'][elementsSitesIndex]['children'].findIndex(element => element.id === item.farm_id)
                    const elementsGensIndex = this.state.optionData[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex]['children'].findIndex(element => element.id === item.id)
                    newArray = [...this.state.optionData]
                    newArray[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex]['children'][elementsGensIndex]['children'] = item['children'] //{...newArray[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex][elementsGensIndex]['children'], children: item['children']} 
                }
                console.log(newArray);
                this.setState({
                    optionData: newArray,
                }, () => {
                    console.log("New state: ", this.state)
                    this.checkDisables()
                })
            })
            .catch(error => {
                console.log("Error on fetching data")
                console.log(error)
            });
  }
  /**
   * When the user searches without to fullfil all the multiselectors, then the datatree of the 
   * selected item should be fetched and added. This is what this fucntion does. The datatree starts 
   * from the latest item category that is filled.
   */
  // fetchAllData = () => {
  //   const { selectedGenerators, selectedFarms, selectedSites, selectedCompanies } = this.state
  //   let params = {}
  //   if (selectedGenerators.length > 0) {
  //     return
  //   }
  //   else if (selectedFarms.length > 0) {
  //     const farmsIds = selectedFarms.map(farm => farm.id)
  //     params["farm_ids"] = farmsIds
  //   }
  //   else if (selectedSites.length > 0) {
  //     const siteIds = selectedSites.map(site => site.id)
  //     params["site_ids"] = siteIds
  //   }
  //   else if (selectedCompanies.length > 0) {
  //     const compIds = selectedCompanies.map(comp => comp.id)
  //     params["comp_ids"] = compIds
  //   } else {
  //     this.setAlert("warning", "Please, fill the reaquest fields.")
  //     return
  //   }
  //   const requestRoute = process.env.REACT_APP_API_URL + "datatree"
  //   axios.get(requestRoute, {
  //     headers: { Authorization: `Bearer ${auth.token}` },
  //     params,
  //     paramsSerializer: params => {
  //       return qs.stringify(params)
  //     }
  //   }).then(response => {
  //     let results = response.data
  //     if (selectedFarms.length > 0) {
  //       let upldatedSelectedFarms = selectedFarms
  //       let newSelectedGens = selectedGenerators
  //       upldatedSelectedFarms.forEach(farm => farm['children'] = results.filter(f => farm.id === f.id)[0]['generators'])
  //       results.map(farm => farm['generators'].map(gen => newSelectedGens.push(gen)))
  //       this.setState({
  //         newSelectedFarms: upldatedSelectedFarms,
  //         selectedGenerators: newSelectedGens
  //       })
  //     }
  //     else if (selectedSites.length > 0) {
  //       let updatedSites = selectedSites
  //       let newSelectedGens = selectedGenerators
  //       let newSelectedFarms = selectedFarms
  //       updatedSites.forEach(site => site['children'] = results.filter(s => site.id === s.id)[0]['farms'])
  //       results.map(site => site["farms"].map(farm => {
  //         newSelectedFarms.push(farm)
  //         farm['children'] = farm['generators']
  //         farm['children'].map(gen => {
  //           newSelectedGens.push(gen)
  //         })
  //       }))
  //       this.setState({
  //         selectedSites: updatedSites,
  //         selectedGenerators: newSelectedGens,
  //         selectedFarms: newSelectedFarms
  //       })
  //     }
  //     else if (selectedCompanies.length > 0) {
  //       let updatedCompanies = selectedCompanies
  //       let newSelectedGens = selectedGenerators
  //       let newSelectedFarms = selectedFarms
  //       let newSelectedSites = selectedSites
  //       updatedCompanies.forEach(comp => comp['children'] = results.filter(c => comp.id === c.id)[0]['sites'])
  //       results.map(comp => {
  //         comp['children'] = comp['sites']
  //         comp['children'].map(site => {
  //           newSelectedSites.push(site)
  //           site['children'] = site['farms']
  //           site['children'].map(farm => {
  //             newSelectedFarms.push(farm)
  //             farm['children'] = farm['generators']
  //             farm['children'].map(gen => {
  //               newSelectedGens.push(gen)
  //             })
  //           })
  //         })
  //       })
  //       this.setState({
  //         selectedCompaines: updatedCompanies,
  //         selectedGenerators: newSelectedGens,
  //         selectedFarms: newSelectedFarms,
  //         selectedSites: newSelectedSites
  //       })
  //     }
  //   })
  //     .catch(error => {
  //       // this.setAlert("danger", "Errorfull request")
  //       console.log("Got error on loading data tree request")
  //       console.log(error.response)
  //     })
  // }
/**
 * When the user searches without to fullfil all the multiselectors, then the datatree of the 
 * selected item should be fetched and added. This is what this fucntion does. The datatree starts 
 * from the latest item category that is filled.
 */
fetchAllData = () => {
  const { selectedCameras, selectedGenerators, selectedFarms, selectedSites, selectedCompanies } = this.state
  let params = {}
  if (selectedCameras.length > 0) {
      return
  }
  else if (selectedGenerators.length > 0) {
      const genIds = selectedGenerators.map(gen => gen.id)
      params["gen_ids"] = genIds
  }
  else if (selectedFarms.length > 0) {
      const farmsIds = selectedFarms.map(farm => farm.id)
      params["farm_ids"] = farmsIds
  }
  else if (selectedSites.length > 0) {
      const siteIds = selectedSites.map(site => site.id)
      params["site_ids"] = siteIds
  }
  else if (selectedCompanies.length > 0) {
      const compIds = selectedCompanies.map(comp => comp.id)
      params["comp_ids"] = compIds
  } else {
      this.setAlert("warning", "Please, fill the reaquest fields.")
      return
  }
  const requestRoute = process.env.REACT_APP_API_URL + "datatree"
  axios.get(requestRoute, {
      headers: { Authorization: `Bearer ${auth.token}` },
      params,
      paramsSerializer: params => {
          return qs.stringify(params)
      }
  }).then(response => {
      let results = response.data
      if (selectedGenerators.length > 0) {
          let upldatedSelectedGenerators = selectedGenerators
          let newSelectedCameras = selectedCameras
          upldatedSelectedGenerators.forEach(gen => gen['children'] = results.filter(f => gen.id === f.id)[0]['cameras'])
          results.map(gen => gen['cameras'].map(cam => newSelectedCameras.push(cam)))
          this.setState({
              newSelectedGenerators: upldatedSelectedGenerators,
              selectedCameras: newSelectedCameras
          })
      } else  if (selectedFarms.length > 0) {
          let upldatedSelectedFarms = selectedFarms
          let newSelectedGens = selectedGenerators
          let newSelectedCameras = selectedCameras
          upldatedSelectedFarms.forEach(farm => farm['children'] = results.filter(f => farm.id === f.id)[0]['generators'])
          results.map(farm => farm['generators'].map(gen => {
              newSelectedGens.push(gen)
              gen['children'] = gen['cameras'];
              gen['children'].map(camera => {
                  newSelectedCameras.push(camera);
              })
          }))
          this.setState({
              newSelectedFarms: upldatedSelectedFarms,
              selectedGenerators: newSelectedGens,
              selectedCameras: newSelectedCameras
          })
      }
      else if (selectedSites.length > 0) {
          let updatedSites = selectedSites
          let newSelectedGens = selectedGenerators
          let newSelectedFarms = selectedFarms
          let newSelectedCameras = selectedCameras
          updatedSites.forEach(site => site['children'] = results.filter(s => site.id === s.id)[0]['farms'])
          results.map(site => site["farms"].map(farm => {
              newSelectedFarms.push(farm)
              farm['children'] = farm['generators']
              farm['children'].map(gen => {
                  newSelectedGens.push(gen)
                  gen['children'] = gen['cameras'];
                  gen['children'].map(camera => {
                      newSelectedCameras.push(camera);
                  })
              })
          }))
          this.setState({
              selectedSites: updatedSites,
              selectedGenerators: newSelectedGens,
              selectedFarms: newSelectedFarms,
              selectedCameras: newSelectedCameras
          })
      }
      else if (selectedCompanies.length > 0) {
          let updatedCompanies = selectedCompanies
          let newSelectedGens = selectedGenerators
          let newSelectedFarms = selectedFarms
          let newSelectedSites = selectedSites
          let newSelectedCameras = selectedCameras
          updatedCompanies.forEach(comp => comp['children'] = results.filter(c => comp.id === c.id)[0]['sites'])
          results.map(comp => {
              comp['children'] = comp['sites']
              comp['children'].map(site => {
                  newSelectedSites.push(site)
                  site['children'] = site['farms']
                  site['children'].map(farm => {
                      newSelectedFarms.push(farm)
                      farm['children'] = farm['generators']
                      farm['children'].map(gen => {
                          newSelectedGens.push(gen)
                          gen['children'] = gen['cameras'];
                          gen['children'].map(camera => {
                              newSelectedCameras.push(camera);
                          })
                      })
                  })
              })
          })
          this.setState({
              selectedCompaines: updatedCompanies,
              selectedGenerators: newSelectedGens,
              selectedFarms: newSelectedFarms,
              selectedSites: newSelectedSites,
              selectedCameras: newSelectedCameras
          }, () => {
            this.storeSearchParamsInLocalStorage();
          })
      }
  })
      .catch(error => {
          // this.setAlert("danger", "Errorfull request")
          console.log("Got error on loading data tree request")
          console.log(error.response)
      })
}

  /**
   * Checks which of the multiselects should be disabled or not. 
   */
  checkDisables = () => {

    let { selectedCompanies, selectedSites, selectedFarms, selectedGenerators } = this.state
    let disables = {
      sitesDisabled: !selectedCompanies.length > 0,
      farmsDisabled: !selectedSites.length > 0,
      generatorsDisabled: !selectedFarms.length > 0,
      camerasDisabled: !selectedGenerators.length > 0,
    }
    return disables
  }

/**
 * Updates and syncs the selected item in the states onSelect event
 * @param {String} category 
 * @param {Array} selectedList 
 * @param {Object} selectedItem 
 */
onSelect = (category, selectedList, selectedItem) => {
  if (category === 'camera') {
      let selected = this.state.selectedCameras;
      selected.push(selectedItem)
      this.setState({
          selectedCameras: selected,
      })
      return
  }
  this.fetchData(category, selectedItem)
  
 
  // until now the below is useless
  if (category === 'generator') {
      let selected = this.state.selectedGenerators;
      selected.push(selectedItem)
      this.setState({
          selectedGenerators: selected,
      }, () => {this.renderGeneratorSelect();})
  }
  else if (category === "company") {
      let selected = this.state.selectedCompanies;
      selected.push(selectedItem)
      this.setState({
          selectedCompanies: selected
      }, () => {this.renderCompanySelect()})
  }
  else if (category === "site") {
      let selected = this.state.selectedSites;
      selected.push(selectedItem)
      this.setState({
          selectedSites: selected
      }, () => { this.renderSiteSelect()})
  }
  else if (category === "farm") {
      let selected = this.state.selectedFarms;
      selected.push(selectedItem)
      this.setState({
          selectedFarms: selected
      }, () => { this.renderFarmSelect() });
  }

}
/**
* Updates and syncs the selected item in the states onRemove event
* @param {String} category 
* @param {Array} selectedList 
* @param {Object} selectedItem 
*/
onRemove = (category, selectedList, removedItem) => {
  if (category === "company") {
      const elementsIndex = this.state.optionData.findIndex(element => element.id === removedItem.id)
      let newArray = [...this.state.optionData]
      newArray[elementsIndex]['children'] = []
      let newSelectedCompanies = this.state.selectedCompanies
      newSelectedCompanies = newSelectedCompanies.filter(comp => comp.id !== removedItem.id)
      this.setState({
          optionData: newArray,
          selectedCompanies: newSelectedCompanies
      }, () => {
          console.log("New state: ", this.state)
          // this.checkDisables()
          this.renderCompanySelect();
      })
      this.resetSitesValues()
  }
  else if (category === "site") {
      const elementsIndex = this.state.optionData.findIndex(element => element.id === removedItem.company_id)
      const elementsSitesIndex = this.state.optionData[elementsIndex]['children'].findIndex(element => element.id === removedItem.id)
      let newArray = [...this.state.optionData]
      newArray = [...this.state.optionData]
      newArray[elementsIndex]['children'][elementsSitesIndex]['children'] = []
      let newSelectedSites = this.state.selectedSites
      newSelectedSites = newSelectedSites.filter(site => site.id !== removedItem.id)
      this.setState({
          optionData: newArray,
          selectedSites: newSelectedSites
      }, () => {
          console.log("New state: ", this.state)
          // this.checkDisables()
          this.renderSiteSelect();
      })
      this.resetFarmsValues()
  }
  else if (category === "farm") {
      const elementsIndex = this.state.optionData.findIndex(element => element.id === removedItem.company_id)
      const elementsSitesIndex = this.state.optionData[elementsIndex]['children'].findIndex(element => element.id === removedItem.site_id)
      const elementsFarmsIndex = this.state.optionData[elementsIndex]['children'][elementsSitesIndex]['children'].findIndex(element => element.id === removedItem.id)
      let newArray = [...this.state.optionData]
      newArray = [...this.state.optionData]
      newArray[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex]['children'] = []
      let newSelectedFarms = this.state.selectedFarms
      newSelectedFarms = newSelectedFarms.filter(farm => farm.id !== removedItem.id)
      this.setState({
          optionData: newArray,
          selectedFarms: newSelectedFarms
      }, () => {
          console.log("New state: ", this.state)
          // this.checkDisables()
          this.renderFarmSelect();
      })
      this.resetGeneratorsValues()
  }
  else if (category === "generator") {
      const elementsIndex = this.state.optionData.findIndex(element => element.id === removedItem.company_id)
      const elementsSitesIndex = this.state.optionData[elementsIndex]['children'].findIndex(element => element.id === removedItem.site_id)
      const elementsFarmsIndex = this.state.optionData[elementsIndex]['children'][elementsSitesIndex]['children'].findIndex(element => element.id === removedItem.farm_id)
      const elementsGeneratorIndex = this.state.optionData[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex]['children'].findIndex(element => element.id === removedItem.id)
      let newArray = [...this.state.optionData]
      newArray = [...this.state.optionData]
      newArray[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex]['children'][elementsGeneratorIndex]['children'] = []
      //--------
      let newSelectedGenerators = this.state.selectedGenerators
      newSelectedGenerators = newSelectedGenerators.filter(gen => gen.id !== removedItem.id)
      this.setState({
          optionData: newArray,
          selectedGenerators: newSelectedGenerators
      }, () => {
          console.log("New state: ", this.state)
          // this.checkDisables()
          this.renderGeneratorSelect();
      });
      this.resetCamerasValues();
  }
  else if (category === "camera") {
      let newSelectedCameras = this.state.selectedCameras
      newSelectedCameras = newSelectedCameras.filter(cam => cam.id !== removedItem.id)
      this.setState({
          selectedCameras: newSelectedCameras
      }, () => {
          console.log("New state: ", this.state)
          // this.checkDisables()
          this.renderCameraSelect();
      }); 
  }

  
}
  renderGeneratorSelect() {
    if (typeof (this.props.onGeneratorSelect) !== "undefined") {
      this.props.onGeneratorSelect(this.state.selectedCompanies, this.state.selectedSites, this.state.selectedFarms, this.state.selectedGenerators);
    }
  }
  renderCameraSelect() {
    if (typeof (this.props.renderCameraSelect) !== "undefined") {
      this.props.renderCameraSelect(this.state.selectedCompanies, this.state.selectedSites, this.state.selectedFarms, this.state.selectedGenerators, this.state.selectedCameras);
    }
  }
  renderGeneratorRemove(removeItem) {
    if (typeof (this.props.onGeneratorRemove) !== "undefined") {
      this.props.onGeneratorRemove(removeItem);
    }
  }

  renderFarmSelect() {
    console.log(this.props.onFarmSelect);
    if (typeof (this.props.onFarmSelect) !== "undefined") {
      this.props.onFarmSelect(this.state.selectedCompanies, this.state.selectedSites, this.state.selectedFarms);
    }
    this.storeSearchParamsInLocalStorage();
  }
  renderCompanySelect() {
    if (typeof (this.props.onCompanySelect) !== "undefined") {
      this.props.onCompanySelect(this.state.selectedCompanies);
    }
  }
  renderSiteSelect() {
    console.log(this.props.onSiteSelect);
    if (typeof (this.props.onSiteSelect) !== "undefined") {
      this.props.onSiteSelect(this.state.selectedCompanies, this.state.selectedSites);
    }
  }
  
  storeSearchParamsInLocalStorage(pageId) {
    const { eventTypes, extraEventTypes, date } = this.state
    const { selectedCameras, selectedGenerators, selectedFarms, selectedSites, selectedCompanies, hasVideo, sortDirection, sortColumn, cameraMode, selectedBirds } = this.state
    localStorage.setItem('search_date', JSON.stringify(date));
    localStorage.setItem('selected_generators', JSON.stringify(selectedGenerators));
    localStorage.setItem('selected_cameras', JSON.stringify(selectedCameras));
    localStorage.setItem('selected_farms', JSON.stringify(selectedFarms));
    localStorage.setItem('selected_sites', JSON.stringify(selectedSites));
    localStorage.setItem('selected_companies', JSON.stringify(selectedCompanies));

}
resetSearchInLocalStorage() {
    localStorage.removeItem('search_date');
    localStorage.removeItem('selected_cameras');
    localStorage.removeItem('selected_generators');
    localStorage.removeItem('selected_farms');
    localStorage.removeItem('selected_sites');
    localStorage.removeItem('selected_companies');

}
showDataUsingLocalStorageDataOnpageLoad() {
  let savedDateJson = localStorage.getItem('search_date');
  let savedDate = null;
  let date = [];
  console.log('search date value');
  console.log(savedDateJson);
  if( savedDateJson &&  savedDateJson != 'undefined' && typeof(savedDateJson) != "undefined"){
    savedDate = JSON.parse(savedDateJson) || [];
    savedDate.map(date_tmp => {
        date.push(new Date(date_tmp));
    })
  }


  const selectedGenerators = JSON.parse(localStorage.getItem("selected_generators")) || [];
  const selectedCameras = JSON.parse(localStorage.getItem("selected_cameras")) || [];
  const selectedFarms = JSON.parse(localStorage.getItem("selected_farms")) || [];
  const selectedSites = JSON.parse(localStorage.getItem("selected_sites")) || [];
  const selectedCompanies = JSON.parse(localStorage.getItem("selected_companies")) || [];
  // retrieve the data for select box of the selected companies, sites, farms, generators from api
  selectedCompanies.map( company => this.fetchData('company', company)); // fetch sites from the selected company
  selectedSites.map( site => this.fetchData('site', site)); // fetch farms from the selected site
  if( this.state.showGenerators ){
    selectedFarms.map( farm => this.fetchData('farm', farm)); // fetch generators from the selected farm
  }
  if( this.state.showCameras){
    selectedGenerators.map( generator => this.fetchData('generator', generator)); // fetch cameras from the selected generator
  }



  this.setState({ date, selectedGenerators, selectedFarms, selectedSites, selectedCompanies, selectedCameras}, () => {
    this.renderFarmSelect()
  })

}
    /**
     * Synchronises the new date with the state
     * @param {Date} date 
     */
    onChangeDate = (date) => {
      console.log("date-change: ", date)
      this.setState({ date });
      if(this.props.onChangeDate){
        this.props.onChangeDate(date);
      }
  }

  /**
   * search functionality
   * @returns
   */
  search = () => {
    console.log('on search');
    this.renderCompanySelect();
    this.renderSiteSelect();
    this.renderFarmSelect();
    this.renderGeneratorSelect();
    this.renderCameraSelect();
     if(this.props.onSearch){
      var onSearchFunction = this.props.onSearch;
      setTimeout(function(){
        onSearchFunction();
      }, 500); 
      
     }
     this.fetchAllData();
  }
  render() {
    const { optionData, selectedCompanies, selectedSites, selectedFarms,
      selectedGenerators, selectedCameras, showGenerators, showCameras, date } = this.state
    const { sitesDisabled, farmsDisabled, generatorsDisabled, camerasDisabled } = this.checkDisables()
    let searchDisabled = true
    if (auth.role_id <= 1)
      searchDisabled = selectedCompanies.length <= 0
    else
      searchDisabled = selectedSites.length <= 0

    /** if (this.props.singleSelect) {
       const sitesData = []
       optionData.map(company => company['children'].map(site => sitesData.push(site)))
       const farmsData = []
       sitesData.map(site => site['children'].map(farm => farmsData.push(farm)))
     } else {
       const sitesData = []
       optionData.map(company => company['children'].map(site => sitesData.push(site)))
       const farmsData = []
       sitesData.map(site => site['children'].map(farm => farmsData.push(farm)))
     } **/
    // const sitesData = []
    // optionData.map(company => company['children'].map(site => sitesData.push(site)))
    // const farmsData = []
    // sitesData.map(site => site['children'].map(farm => farmsData.push(farm)))
    // const generatorsData = []
    // farmsData.map(farm => farm['children'].map(gen => generatorsData.push(gen)))
    const sitesData = []
    optionData.map(company => company['children'].map(site => sitesData.push(site)))
    const farmsData = []
    sitesData.map(site => site['children'].map(farm => farmsData.push(farm)))
    const generatorsData = []
    farmsData.map(farm => farm['children'].map(gen => generatorsData.push(gen)))
    const camerasData = []
    console.log('Generator data');
    console.log(generatorsData);
    generatorsData.map(gen => gen['children'].map(cam => camerasData.push(cam)))

    let selectedValuesForCompanies = [];
    if(this.props.selectedCompany){
      selectedValuesForCompanies = this.props.selectedCompany;
    } else {
      selectedValuesForCompanies = selectedCompanies.map(company=>company);
    }
    return (
      <div>
      <div className="row">
        {auth.role_id <= 1 && <div className={"col mb-3"}>
          <Multiselect
            options={optionData}
            onSelect={(this.props.singleSelect) ? this.onSelectSingle.bind(this, "company") : this.onSelect.bind(this, "company")}
            onRemove={this.onRemove.bind(this, "company")}
            displayValue="name"
            placeholder="Company"
            closeIcon="cancel"
            avoidHighlightFirstOption={true}
            ref={this.multiselectRefCompanies}
            singleSelect={(this.props.singleSelect) ?? false}
            selectedValues={selectedValuesForCompanies}
          />
        </div>}
        <div className={sitesDisabled ? "col disabled" : "col mb-3"}>
          <Multiselect
            options={sitesData}
            selectedValues={selectedSites.map(site => site)}
            onSelect={(this.props.singleSelect) ? this.onSelectSingle.bind(this, "site") : this.onSelect.bind(this, "site")}
            onRemove={this.onRemove.bind(this, "site")}
            displayValue={auth.role_id > 1 ? "name" : "view_name"}
            placeholder="Site"
            closeIcon="cancel"
            avoidHighlightFirstOption={true}
            ref={this.multiselectRefSites}
            singleSelect={(this.props.singleSelect) ?? false}
          />
          {sitesDisabled && <p style={warningStyle}> Unavailable: please select a Company </p >}
          {selectedSites.length > 0 && <button type="button" className="btn btn-link float-right sm" onClick={() => this.resetSitesValues()}>Clear</button>}

        </div>
        <div className={farmsDisabled ? "col disabled" : "col mb-3"}>
          <Multiselect
            options={farmsData}
            selectedValues={selectedFarms.map(farm => farm)}
            onSelect={(this.props.singleSelect) ? this.onSelectSingle.bind(this, "farm") : this.onSelect.bind(this, "farm")}
            onRemove={this.onRemove.bind(this, "farm")}
            displayValue="view_name"
            placeholder="Farm"
            closeIcon="cancel"
            avoidHighlightFirstOption={true}
            ref={this.multiselectRefFarms}
            singleSelect={(this.props.singleSelect) ?? false}
          />
          {farmsDisabled && <p style={warningStyle}> Unavailable: please select a Site </p >}
          {selectedFarms.length > 0 && <button type="button" className="btn btn-link float-right sm" onClick={() => this.resetFarmsValues()}>Clear</button>}

        </div>
        {showGenerators &&
          <div className={generatorsDisabled ? "col disabled" : "col mb-3"}>
            <Multiselect
              options={generatorsData}
              selectedValues={selectedGenerators.map(gen => gen)}
              onSelect={(this.props.singleSelect) ? this.onSelectSingle.bind(this, "generator") : this.onSelect.bind(this, "generator")}
              onRemove={this.onRemove.bind(this, "generator")}
              displayValue="view_name"
              placeholder="Generator"
              closeIcon="cancel"
              avoidHighlightFirstOption={true}
              ref={this.multiselectRefGenerators}
              singleSelect={(this.props.singleSelect) ?? false}
            />
            {generatorsDisabled && <p style={warningStyle}> Unavailable: please select a Farm </p >}
            {selectedGenerators.length > 0 && <button type="button" className="btn btn-link float-right sm" onClick={() => this.resetGeneratorsValues()}>Clear</button>}
          </div>
        }
        {showCameras && <div className={camerasDisabled ? "col disabled" : "col mb-3"}>
            <Multiselect
                options={camerasData}
                selectedValues={selectedCameras.map(cam => cam)}
                onSelect={this.onSelect.bind(this, "camera")}
                onRemove={this.onRemove.bind(this, "camera")}
                displayValue="view_name"
                placeholder="Camera"
                closeIcon="cancel"
                avoidHighlightFirstOption={true}
                ref={this.multiselectRefCameras}
            />
            {camerasDisabled && <p style={warningStyle}> Unavailable: please select a Generator </p >}
            {selectedCameras.length > 0 && <button type="button" className="btn btn-link float-right sm" onClick={() => this.resetCamerasValues()}>Clear</button>}
        </div>
      }
      </div>
      {this.props.moreFilterChildren}
      <div className="d-flex justify-content-center">
        <div className="row">
      {this.props.children}
        {this.props.showDateFilter && <div className="col">
            <FormLabel component="legend"><span style={{ color: "#ffffff" }}>Calendar:</span></FormLabel>
            <DateRangePicker
                date={date}
                onChangeDate={this.onChangeDate}
            />
        </div>}

        {this.props.showBtns && <div className="align-text-bottom col">
            <span style={{ margin: "20px" }} />
            <button className={searchDisabled ? "btn btn-primary disabled" : "btn btn-primary"} style={buttonStyle} onClick={() => this.search()}>{this.props.searchBtnText || 'Search'}</button>
            <button className="btn btn-danger" style={buttonStyle} onClick={() => this.resetAll()} >Reset</button>
        </div>}
        </div>
        </div>
    </div>

    )
  }
}

export default Filters;

const warningStyle = { color: "#7a7979" }
const buttonStyle = { margin: "10px 10px" }